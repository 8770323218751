import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store/storeConfig';
import { useEffect } from 'react';
import { clearToken } from '../store/actions/authActions';
import { openWebsocketConnectionWithToken } from '../api/webSocket';

export const useTokenAndOpenWebsocketConnectionIfNecessary = () => {
    const dispatch = useDispatch();
    const { token, isConnected, locale } = useSelector((state: ApplicationState) => ({
        token: state.auth.token,
        isConnected: state.auth.isConnected,
        locale: state.settings.locale,
    }));

    useEffect(() => {
        /* TODO: Maybe check if token is renewed and reopen connection in that case. */
        if (token && !isConnected) {
            const onError = () => {
                dispatch(clearToken());
                localStorage.removeItem('token');
            };
            openWebsocketConnectionWithToken(locale, dispatch, token, onError);
        }
    }, [token, isConnected]);

    return token;
};
