import { FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { getRoomByName } from '../../store/selector/roomsSelectors';
import { isDoorOpenByCounterName } from '../../store/selector/countersSelectors';
import { ApplicationState } from '../../store/storeConfig';
import DotLoadingSpinner from '../shared/LoadingSpinner/DotLoadingSpinner';
import {
    FaceMask,
    FaceMaskIcon,
    FaceMaskMessage,
    Footer,
    Header,
    KeepDistance,
    KeepDistanceIcon,
    KeepDistanceMessage,
    Message,
    Page,
    TrafficLightArea,
} from './TrafficLight.styles';
import { useNamespaceFromRoute } from '../../hooks/useNamespaceFromRoute';
import { useDecodedParams } from '../../hooks/useDecodedParams';
import { getRemainingCapacity, isRoomLimitReached } from '../../helper/roomHelper';
import { EMPTY_ROOM } from '../../models/room';

const TrafficLight = () => {
    const { roomName, counterName }: { roomName: string; counterName: string } = useDecodedParams();
    const { isLimitReached, remainingCapacity, doorOpen, isLoading } = useSelector((state: ApplicationState) => {
        const room = getRoomByName(state, roomName);
        const isLoading = state.counters.isLoading || state.rooms.isLoading || room === EMPTY_ROOM;
        return {
            isLimitReached: isRoomLimitReached(room),
            remainingCapacity: getRemainingCapacity(room),
            doorOpen: isDoorOpenByCounterName(state, counterName),
            isLoading,
        };
    }, shallowEqual);

    useNamespaceFromRoute();

    const open: boolean = (!isLimitReached && doorOpen) || false;
    return (
        <Page>
            <TrafficLightArea open={open} indeterminate={isLoading}>
                {!isLoading ? (
                    <>
                        <Header>
                            <FormattedMessage id={open ? 'trafficlight.accessAllowed' : 'trafficlight.accessNotAllowed'} />
                        </Header>
                        <Message>
                            <FormattedMessage
                                id={open ? 'trafficlight.remainingCapacity' : 'trafficlight.closedMessage'}
                                values={{ remainingCapacity }}
                            />
                        </Message>
                    </>
                ) : (
                    <DotLoadingSpinner data-testid="loadingSpinner" />
                )}
            </TrafficLightArea>
            <Footer>
                <FaceMask>
                    <FaceMaskIcon />
                    <FaceMaskMessage>
                        <FormattedMessage id="trafficlight.faceMask" />
                    </FaceMaskMessage>
                </FaceMask>
                <KeepDistance>
                    <KeepDistanceIcon />
                    <KeepDistanceMessage>
                        <FormattedMessage id="trafficlight.keepDistance" />
                    </KeepDistanceMessage>
                </KeepDistance>
            </Footer>
        </Page>
    );
};

export default TrafficLight;
