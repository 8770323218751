import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store/storeConfig';
import { FormattedMessage } from 'react-intl';
import { EmptyNamespaceTile, Namespaces, NamespaceTile } from './NamespaceSelection.styles';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { getRoute, RouteEnum } from '../../helper/routeHelper';

const NamespaceSelection = () => {
    const history = useHistory();
    const namespaces = useSelector((state: ApplicationState) => state.namespaces.namespaces);

    useEffect(() => {
        if (namespaces.length === 1) {
            history.push(getRoute(RouteEnum.NamespaceDashboard, { namespaceName: namespaces[0].name }));
        }
    }, [namespaces]);

    return (
        <>
            <h1>
                <FormattedMessage id="namespaces" />
            </h1>
            <p>
                <FormattedMessage id="namespaces.selection" />
            </p>
            <Namespaces>
                {namespaces.length > 0 ? (
                    namespaces.map((namespace, key) => (
                        <NamespaceTile key={key} to={getRoute(RouteEnum.NamespaceDashboard, { namespaceName: namespace.name })}>
                            {namespace.name}
                        </NamespaceTile>
                    ))
                ) : (
                    <>
                        <EmptyNamespaceTile />
                        <EmptyNamespaceTile />
                        <EmptyNamespaceTile />
                    </>
                )}
            </Namespaces>
        </>
    );
};

export default NamespaceSelection;
