import React, { MouseEventHandler } from 'react';
import useCanvas from './useCanvas';

interface Props {
    className?: string | undefined;
    draw: (ctx: CanvasRenderingContext2D) => void;
    onClick: MouseEventHandler<HTMLCanvasElement>;
    height: number;
    width: number;
}

function Canvas(props: Props) {
    const { className, draw, onClick, ...rest } = props;
    const canvasRef = useCanvas(draw);

    return (
        <canvas className={className} onClick={onClick} style={{ height: props.height, width: props.width }} ref={canvasRef} {...rest} />
    );
}

export default Canvas;
