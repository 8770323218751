import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Button from '../Button/Button';
import { lightGray } from '../../styles/theme';

export type SegmentedControlInputSegment<T> = {
    label: string;
    value: T;
};

type Props<T> = {
    disabled?: boolean;
    onChange?: (newValue: T) => void;
    segments: SegmentedControlInputSegment<T>[];
    value: T;
};

export const SelectedSegment = styled(Button)`
    background-color: ${(props) => props.theme.roomTile.activeColor};
`;

export const UnSelectedSegment = styled(Button)`
    background-color: ${lightGray};
`;

export const SegmentContainer = styled.div`
    display: flex;
`;

const SegmentedControl = <T extends unknown>({ disabled, segments, value, onChange }: Props<T>) => {
    const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);
    const selectSegment = (segmentValue: T, index: number) => {
        setSelectedSegmentIndex(index);
        onChange && onChange(segmentValue);
    };

    useEffect(() => {
        setSelectedSegmentIndex(segments.findIndex((segment) => segment.value === value) || 0);
    }, [value]);

    return (
        <SegmentContainer>
            {segments.map((segment, index) => (
                <Fragment key={index}>
                    {selectedSegmentIndex === index ? (
                        <SelectedSegment disabled={disabled}>{segment.label}</SelectedSegment>
                    ) : (
                        <UnSelectedSegment onClick={() => selectSegment(segment.value, index)} disabled={disabled}>
                            {segment.label}
                        </UnSelectedSegment>
                    )}
                </Fragment>
            ))}
        </SegmentContainer>
    );
};

export default SegmentedControl;
