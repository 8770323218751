import { Loading } from './DoorDetails.styles';
import { blue, lightBlue } from '../styles/theme';
import { FormattedMessage } from 'react-intl';
import Button from '../shared/Button/Button';
import { changeDoorStatus } from '../../api/webSocket';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store/storeConfig';
import { HandStop } from '@styled-icons/zondicons';
import styled from 'styled-components/macro';
import { CounterState, isCounterLoading } from '../../models/counter';

const ButtonContent = styled.div`
    display: flex;
`;
const HandStopIcon = styled(HandStop)`
    width: 1rem;
    height: 1rem;
`;

const CloseDoorButton = ({ counter }: { counter: CounterState }) => {
    const {
        isLoading,
        isConnected,
        namespaceId,
    }: {
        isLoading: boolean;
        isConnected: boolean;
        namespaceId: string;
    } = useSelector((state: ApplicationState) => {
        const isLoading = isCounterLoading(counter);
        const isConnected = state.auth.isConnected;
        const namespaceId = state.namespaces.selectedNamespaceId;

        return {
            isLoading,
            isConnected,
            namespaceId,
        };
    });
    const updateDoorStatus = () => {
        const newDoorStatus = !counter.doorOpen;
        changeDoorStatus(counter.id, newDoorStatus, namespaceId);
    };
    return (
        <Button data-testid="closeDoorButton" onClick={updateDoorStatus} disabled={isLoading || !isConnected}>
            {isLoading ? (
                <Loading data-testid="loadingOrStateUpdateIndicator" color={blue} highlightColor={lightBlue} dotSize="0.5rem" />
            ) : (
                <ButtonContent>
                    <FormattedMessage id={counter.doorOpen ? 'door.closeButton' : 'door.openButton'} />
                    &nbsp;
                    <HandStopIcon />
                </ButtonContent>
            )}
        </Button>
    );
};

export default CloseDoorButton;
