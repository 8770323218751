import { RoomWithReport } from '../components/AllRoomsReport/AllRoomsReport.selector';
import { Room } from '../models/room';
import { CounterStateWithAssignedRooms } from '../models/counter';

export const orderByRoomName = ({ room: roomA }: RoomWithReport, { room: roomB }: RoomWithReport): number => orderByName(roomA, roomB);

// TODO: locale bei SMRTCNTR-45 setzen
export const orderByName = ({ name: nameA }: { name?: string }, { name: nameB }: { name?: string }): number =>
    orderAlphabetically(nameA || '', nameB || '');

export const orderByCounterName = (counterA: CounterStateWithAssignedRooms, counterB: CounterStateWithAssignedRooms): number =>
    orderByName(counterA.counterState, counterB.counterState);

export const orderAlphabetically = (a: string, b: string) =>
    a.localeCompare(b, undefined, {
        numeric: true,
        ignorePunctuation: true,
    });

export const orderByLimit = (roomA: Room, roomB: Room) => (roomA.limit || 0) - (roomB.limit || 0);

export const nonMutatingSort = <T>(array: T[], compareFn: (a: T, b: T) => number): T[] => [...array].sort(compareFn);
