import styled, { css } from 'styled-components/macro';
import NavigationBackgroundImage from '../../../assets/navigation-background.png';
import { Link } from 'react-router-dom';
import { Close } from '@styled-icons/material';
import { UserCircle } from '@styled-icons/boxicons-solid';

type NavbarProps = { isNavigationOpen: boolean; translateX: number };

export const Navbar = styled.div.attrs<NavbarProps, { style: { [key: string]: string } }>(({ isNavigationOpen, translateX }) => ({
    style: {
        transform: `translateX(${isNavigationOpen ? 0 - translateX + 'px' : 'calc(-100% - 7px)'})`,
        transition: translateX !== 0 ? 'none' : 'transform 0.5s ease-out',
    },
}))<NavbarProps>`
    display: flex;
    touch-action: none;
    flex-direction: column;
    background: ${(props) => props.theme.navigation.backgroundColor};
    background-image: url(${NavigationBackgroundImage});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 0;
    position: fixed;
    z-index: 1100;
    box-shadow: 0 0 8px 7px rgba(0, 0, 0, 0.2);
    height: 100vh;
    width: 100vw;
    @media (min-width: ${(props) => props.theme.responsiveDesign.smartphoneLandscapeWidth}) {
        width: 350px;
    }
`;
export const NavigationContent = styled.div`
    padding: 0 2rem;

    display: flex;
    flex-direction: column;
`;
export const LogoInNavigation = styled.img`
    align-self: center;
    margin-bottom: 2rem;
    width: 50%;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletLandscapeWidth}) {
        width: 80%;
    }
`;

const NavigationEntryBase = css`
    font-size: 1.5rem;
    line-height: 2.5rem;
    display: block;
`;

export const NavigationEntry = styled(Link)<{ $isActiveEntry: boolean }>`
    ${NavigationEntryBase}
    color: ${(props) => (props.$isActiveEntry ? props.theme.navigation.activeColor : props.theme.navigation.color)};
`;

export const SubNavigationEntry = styled(Link)<{ $isActiveEntry: boolean; $disabled: boolean }>`
    ${NavigationEntryBase}
    color: ${(props) =>
        props.$isActiveEntry
            ? props.theme.navigation.activeColor
            : props.$disabled
            ? props.theme.navigation.disabledColor
            : props.theme.navigation.color};
    pointer-events: ${(props) => (props.$disabled ? 'none' : 'initial')};
    margin-left: 2rem;
`;

export const SubNavigationEntries = styled.div`
    margin-bottom: 2rem;
`;

export const UserProfile = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto 1fr;
    align-items: center;
`;
export const MenuDivider = styled.hr`
    margin: 2rem 0;
    border-color: ${(props) => props.theme.navigation.dividerColor};
`;
export const UserIcon = styled(UserCircle)`
    color: ${(props) => props.theme.navigation.color};

    width: 3rem;
    height: 3rem;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletLandscapeWidth}) {
        width: 4rem;
        height: 4rem;
    }
`;
const ButtonStyle = css`
    background-color: transparent;
    color: ${(props) => props.theme.button.textColor};
    padding: 0.5rem;
    border: none;
    display: flex;
    font-size: 1.5rem;
    font-family: inherit;

    &:focus {
        outline: none;
        color: ${(props) => props.theme.navigation.hoverColor};
    }

    &:hover:not(:disabled) {
        outline: none;
        color: ${(props) => props.theme.navigation.hoverColor};
    }
`;
export const Overlay = styled.div<{ isNavigationOpen: boolean }>`
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: white;
    z-index: 102;
    opacity: ${(props) => (props.isNavigationOpen ? '0.8' : '0')};
    transition: opacity 0.5s ease-out;
    pointer-events: ${(props) => (props.isNavigationOpen ? 'initial' : 'none')};
`;
export const CloseIcon = styled(Close)`
    width: 2rem;
    height: 2rem;
`;
export const LogoutButton = styled.button`
    ${ButtonStyle}
    width: 100%;
    height: 2rem;
    align-items: center;
    justify-content: left;
`;
export const CloseButton = styled.button`
    ${ButtonStyle}

    align-items: center;
    align-self: flex-end;
    padding: 0;
    margin: 1rem;
`;
