import { ApplicationState } from '../../store/storeConfig';
import { nonMutatingSort, orderByName } from '../../helper/sortingHelper';
import { isRoomCritical, isRoomLimitReached } from '../../helper/roomHelper';
import { getCriticalThreshold } from '../../store/selector/namespaceSelectors';
import { Room } from '../../models/room';

export type NamespaceDashboardSelectorResult = {
    criticalRooms: Room[];
    isLoadingRooms: boolean;
    roomCount: number;
    isLoadingDoors: boolean;
    closedDoors: ClosedDoor[];
    criticalThreshold: number;
    atLeastOneRoomFull: boolean;
};

export type ClosedDoor = { name: string; manuallyClosed: boolean; assignedToRoomWithLimitReached: boolean };

export const namespaceDashboardSelector = (state: ApplicationState): NamespaceDashboardSelectorResult => {
    const criticalThreshold = getCriticalThreshold(state);
    const criticalRooms = nonMutatingSort(
        state.rooms.rooms.filter((room) => isRoomCritical(room, criticalThreshold / 100)),
        orderByName
    );
    const fullRooms = state.rooms.rooms.filter((room) => isRoomLimitReached(room));
    const atLeastOneRoomFull = state.rooms.rooms.some((room) => isRoomLimitReached(room));
    const fullRoomsIds = fullRooms.map((room) => room.id);
    const closedDoors: ClosedDoor[] = state.counters.counterStates.flatMap((counterState) => {
        if (
            [...counterState.roomIdsFilledFromOutValue, ...counterState.roomIdsFilledFromInValue].some((roomId) =>
                fullRoomsIds.includes(roomId)
            )
        ) {
            return {
                name: counterState.name,
                manuallyClosed: !counterState.doorOpen,
                assignedToRoomWithLimitReached: true,
            };
        }
        if (!counterState.doorOpen) {
            return {
                name: counterState.name,
                manuallyClosed: !counterState.doorOpen,
                assignedToRoomWithLimitReached: false,
            };
        }
        return [];
    });

    return {
        criticalRooms: criticalRooms,
        isLoadingRooms: state.rooms.isLoading,
        isLoadingDoors: state.counters.isLoading,
        roomCount: state.rooms.rooms.length,
        closedDoors: nonMutatingSort(closedDoors, orderByName),
        criticalThreshold,
        atLeastOneRoomFull,
    };
};
