import { ActionType, createAction } from 'typesafe-actions';
import { CounterState, UpdateCounterState } from '../../models/counter';

export const setInitialCounters = createAction('INITIAL_COUNTERS')<CounterState[]>();
export const updateCounterState = createAction('UPDATE_COUNTER_STATE')<UpdateCounterState>();
export const setDoorStatus = createAction('SET_DOOR_STATUS')<{ counterId: string; doorOpen: boolean }>();
export const setCounters = createAction('SET_COUNTERS')<CounterState[]>();

export type CounterActions =
    | ActionType<typeof setInitialCounters>
    | ActionType<typeof updateCounterState>
    | ActionType<typeof setDoorStatus>
    | ActionType<typeof setCounters>;
