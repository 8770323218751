import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import DoorTile from '../shared/DoorTile/DoorTile';
import { UrlParams } from '../../models';
import RoomTile, { DisplayMode } from '../shared/RoomTile/RoomTile';
import { nonMutatingSort, orderByCounterName } from '../../helper/sortingHelper';
import useMedia from '../../hooks/useMedia';
import { tabletPortraitWidth } from '../styles/theme';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useEffect, useState } from 'react';
import MobilePagination from '../shared/MobilePagination/MobilePagination';
import CounterWithAssignedRooms from './CounterWithAssignedRooms';
import { useDecodedParams } from '../../hooks/useDecodedParams';
import UrlParamValidator from '../UrlParamValidator/UrlParamValidator';
import {
    AddPersonButtonContainer,
    Container,
    Counters,
    CurrentRoom,
    Details,
    DoorAndStats,
    DoorContainer,
    MobileContainer,
    Number,
    TopContainer,
    UtilizationGrid,
} from './RoomDetail.styles';
import PersonsPerHourChart from './PersonsPerHourChart';
import { roomDetailSelector, RoomDetailSelectorResult } from './RoomDetail.selector';
import { ApplicationState } from '../../store/storeConfig';
import { getReport } from '../../store/actions/roomsActions';
import { getHidden, hasHidden, isNotHidden } from '../../helper/counterHelper';
import AddPersonButton from './AddPersonButton';
import DistancerCanvas from './DistancerCanvas';
import { getDistancerReport } from '../../store/actions/distancerActions';
import { EMPTY_ROOM, OUTSIDE_ROOM } from '../../models/room';
import { EMPTY_COUNTER_STATE } from '../../models/counter';

const RoomDetail = () => {
    const { roomName, namespaceName }: UrlParams = useDecodedParams();
    const isTabletPortrait = useMedia(`(min-width: ${tabletPortraitWidth})`);
    const [activeIndex, setActiveIndex] = useState(0);
    const dispatch = useDispatch();
    const {
        currentNumberOfPersons,
        capacity,
        counters,
        room,
        isLoading,
        isLimitReached,
        selectedNamespaceId,
        isConnected,
        roomReportEntries,
        roomReportLoading,
        distancerReportEntries,
        distancerReportLoading,
        distancerCurrentNumberOfDetections,
        criticalThreshold,
    }: RoomDetailSelectorResult = useSelector((state: ApplicationState) => roomDetailSelector(state, roomName));
    const maxNumberOfPersons = Math.max(...counters.map((counter) => Math.max(counter.counterState.in, counter.counterState.out)));
    useEffect(() => {
        if (isConnected && !isLoading && selectedNamespaceId !== '' && room.id !== EMPTY_ROOM.id && room.id !== OUTSIDE_ROOM.id) {
            dispatch(getReport({ namespaceId: selectedNamespaceId, type: 'room-report', roomId: room.id }));
            dispatch(getDistancerReport({ namespaceId: selectedNamespaceId, distancerId: 'aws-console-test' }));
        }
    }, [room, isLoading, isConnected, selectedNamespaceId]);

    return (
        <>
            <h1>
                <FormattedMessage id="room.title" values={{ roomName }} />
            </h1>
            <UrlParamValidator namespaceName={namespaceName} roomName={roomName}>
                <TopContainer>
                    <div>
                        <DoorAndStats>
                            <Details>
                                <h2>
                                    <FormattedMessage id="room.details" />
                                </h2>
                                <UtilizationGrid>
                                    <FormattedMessage id="room.currentUtilization" />
                                    <Number>{currentNumberOfPersons}</Number>
                                    <FormattedMessage id="room.capacity" />
                                    {capacity && <Number data-testid="room.capacity">{capacity}</Number>}
                                    {hasHidden(counters) && (
                                        <AddPersonButtonContainer>
                                            <AddPersonButton counter={getHidden(counters).counterState} add={true} />
                                            <AddPersonButton counter={getHidden(counters).counterState} add={false} />
                                        </AddPersonButtonContainer>
                                    )}
                                </UtilizationGrid>
                            </Details>
                            <div>
                                <h2>
                                    <FormattedMessage id="room.numberOfPersonsReport" />
                                </h2>
                                <div>
                                    <FormattedMessage id="room.numberOfPersonsReportLabel" />
                                </div>
                                {roomReportEntries && (
                                    <PersonsPerHourChart
                                        roomReportEntries={roomReportEntries}
                                        roomReportLoading={roomReportLoading}
                                        limit={capacity}
                                        criticalThreshold={criticalThreshold}
                                        actualNumberOfPersons={room.numberOfPersons}
                                    />
                                )}
                            </div>
                        </DoorAndStats>

                        <div>
                            <h2>
                                <FormattedMessage id="room.doors" />
                            </h2>
                            <DoorContainer>
                                <CurrentRoom>
                                    <RoomTile room={room} isSelected={true} criticalThreshold={criticalThreshold} />
                                </CurrentRoom>
                                <Counters>
                                    {!isLoading &&
                                        (isTabletPortrait ? (
                                            nonMutatingSort(
                                                counters.filter(isNotHidden),
                                                orderByCounterName
                                            ).map((counterStateWithAssignedRooms, counterIndex) => (
                                                <CounterWithAssignedRooms
                                                    roomName={roomName}
                                                    isTabletPortrait={isTabletPortrait}
                                                    isLimitReached={isLimitReached}
                                                    maxNumberOfPersons={maxNumberOfPersons}
                                                    selectedRoomFilledFromInValue={
                                                        counterStateWithAssignedRooms.selectedRoomFilledFromInValue
                                                    }
                                                    counterStateWithAssignedRooms={counterStateWithAssignedRooms}
                                                    key={`counter-${counterIndex}`}
                                                    criticalThreshold={criticalThreshold}
                                                />
                                            ))
                                        ) : (
                                            <MobileContainer>
                                                <Swiper
                                                    spaceBetween={16}
                                                    observer
                                                    onActiveIndexChange={({ activeIndex }) => setActiveIndex(activeIndex)}
                                                >
                                                    {nonMutatingSort(counters.filter(isNotHidden), orderByCounterName).map(
                                                        (counterStateWithAssignedRooms, counterIndex) => (
                                                            <SwiperSlide key={'right-counter-' + counterIndex}>
                                                                <CounterWithAssignedRooms
                                                                    roomName={roomName}
                                                                    isTabletPortrait={isTabletPortrait}
                                                                    isLimitReached={isLimitReached}
                                                                    maxNumberOfPersons={maxNumberOfPersons}
                                                                    selectedRoomFilledFromInValue={
                                                                        counterStateWithAssignedRooms.selectedRoomFilledFromInValue
                                                                    }
                                                                    counterStateWithAssignedRooms={counterStateWithAssignedRooms}
                                                                    key={`counter-${counterIndex}`}
                                                                    criticalThreshold={criticalThreshold}
                                                                />
                                                            </SwiperSlide>
                                                        )
                                                    )}
                                                </Swiper>
                                                <MobilePagination items={counters.filter(isNotHidden)} activeIndex={activeIndex} />
                                            </MobileContainer>
                                        ))}
                                    {isLoading && (
                                        <Container data-testid="empty-counter-with-empty-room">
                                            <DoorTile counter={EMPTY_COUNTER_STATE} />
                                            <RoomTile
                                                room={EMPTY_ROOM}
                                                displayMode={isTabletPortrait ? DisplayMode.Tile : DisplayMode.MiniTile}
                                                criticalThreshold={criticalThreshold}
                                            />
                                        </Container>
                                    )}
                                </Counters>
                            </DoorContainer>
                        </div>
                    </div>
                    <div>
                        <h2>Distancer</h2>
                        {distancerReportEntries && (
                            <PersonsPerHourChart
                                roomReportEntries={distancerReportEntries}
                                roomReportLoading={distancerReportLoading}
                                criticalThreshold={criticalThreshold}
                                actualNumberOfPersons={distancerCurrentNumberOfDetections}
                            />
                        )}
                        <DistancerCanvas />
                    </div>
                </TopContainer>
            </UrlParamValidator>
        </>
    );
};
export default RoomDetail;
