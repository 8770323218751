import styled, { css } from 'styled-components/macro';
import { NavigateBefore, NavigateNext } from '@styled-icons/material';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import { tabletLandscapeWidth } from '../../styles/theme';
import useMedia from '../../../hooks/useMedia';

const Icon = css`
    width: 1.5rem;
    height: 1.5rem;
`;
const NavigateBeforeIcon = styled(NavigateBefore)`
    ${Icon}
`;

const NavigateNextIcon = styled(NavigateNext)`
    ${Icon}
`;

const Navbar = styled.nav`
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: white;
    margin-top: -2rem;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    width: 100%;
    z-index: 101;
`;

const BreadcrumbNavigation = ({ navigationEntries }: { navigationEntries: { text: string; path?: string }[] }) => {
    const isWide = useMedia(`(min-width: ${tabletLandscapeWidth})`);
    const parentNavigationEntry = navigationEntries.length >= 2 ? navigationEntries[navigationEntries.length - 2] : undefined;

    return (
        <Navbar>
            {isWide
                ? navigationEntries.map(({ text, path }, index) => {
                      const isLast = index === navigationEntries.length - 1;
                      return (
                          <Fragment key={index}>
                              {isLast || path === undefined ? <span>{text}</span> : <Link to={path}>{text}</Link>}
                              {!isLast && <NavigateNextIcon />}
                          </Fragment>
                      );
                  })
                : parentNavigationEntry && (
                      <>
                          <NavigateBeforeIcon />
                          <Link to={parentNavigationEntry.path || ''}>{parentNavigationEntry.text}</Link>
                      </>
                  )}
        </Navbar>
    );
};

export default BreadcrumbNavigation;
