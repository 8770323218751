import { Loading } from '../DoorDetails/DoorDetails.styles';
import { blue, lightBlue } from '../styles/theme';
import { FormattedMessage } from 'react-intl';
import Button from '../shared/Button/Button';
import { changeCounterState } from '../../api/webSocket';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store/storeConfig';
import { User } from '@styled-icons/zondicons';
import styled from 'styled-components/macro';
import { ChangeCounterStateMessage, CounterState, isCounterLoading } from '../../models/counter';

const ButtonContent = styled.div`
    display: flex;
`;
const UserIcon = styled(User)`
    width: 1rem;
    height: 1rem;
`;

const AddPersonButton = ({ counter, add }: { counter: CounterState; add: boolean }) => {
    const {
        isLoading,
        isConnected,
        namespaceId,
    }: {
        isLoading: boolean;
        isConnected: boolean;
        namespaceId: string;
    } = useSelector((state: ApplicationState) => {
        const isLoading = isCounterLoading(counter);
        const isConnected = state.auth.isConnected;
        const namespaceId = state.namespaces.selectedNamespaceId;

        return {
            isLoading,
            isConnected,
            namespaceId,
        };
    });
    const addPerson = () => {
        const newCounterState: ChangeCounterStateMessage = {
            id: counter.id,
            counterIn: add ? counter.in + 1 : counter.in,
            counterOut: add ? counter.out : counter.out + 1,
            counterDiff: counter.in - counter.out + (add ? 1 : -1),
            counterTimestamp: Date.now(),
            counterVersion: Date.now(),
        };
        changeCounterState(newCounterState, namespaceId);
    };
    return (
        <Button data-testid="addPersonButton" onClick={addPerson} disabled={isLoading || !isConnected}>
            {isLoading ? (
                <Loading data-testid="loadingOrStateUpdateIndicator" color={blue} highlightColor={lightBlue} dotSize="0.5rem" />
            ) : (
                <ButtonContent>
                    <FormattedMessage id={add ? 'room.addPerson' : 'room.remPerson'} />
                    &nbsp;
                    <UserIcon />
                </ButtonContent>
            )}
        </Button>
    );
};

export default AddPersonButton;
