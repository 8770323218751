import smartCountrLogoInvert from '../../../assets/SmartCountrWhite.png';
import { clearToken } from '../../../store/actions/authActions';
import { FormattedMessage } from 'react-intl';
import { TouchEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    CloseButton,
    CloseIcon,
    LogoInNavigation,
    LogoutButton,
    MenuDivider,
    Navbar,
    NavigationContent,
    NavigationEntry,
    Overlay,
    SubNavigationEntries,
    SubNavigationEntry,
    UserIcon,
    UserProfile,
} from './Navigation.styles';
import { throttle } from '../../../helper/throttle';
import { useDecodedParams } from '../../../hooks/useDecodedParams';
import { ApplicationState } from '../../../store/storeConfig';
import { nonMutatingSort, orderByName } from '../../../helper/sortingHelper';
import { getRoute, RouteEnum } from '../../../helper/routeHelper';

const Navigation = ({
    setNavigationOpen,
    isNavigationOpen,
}: {
    setNavigationOpen: (isOpen: boolean) => void;
    isNavigationOpen: boolean;
}) => {
    const { namespaceName }: { namespaceName: string } = useDecodedParams();
    const { pathname: activeRoute } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [touchStartX, setTouchStartX] = useState(0);
    const [touchTranslateX, setTouchTranslateX] = useState(0);
    const namespaces = useSelector((state: ApplicationState) => state.namespaces.namespaces);
    useEffect(() => {
        const escapeHandler = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setNavigationOpen(false);
            }
        };

        if (isNavigationOpen) {
            window.addEventListener('keydown', escapeHandler);
        }
        return () => {
            window.removeEventListener('keydown', escapeHandler);
        };
    }, [isNavigationOpen]);

    const onTouchEnd = () => {
        if (touchTranslateX > 150) {
            setNavigationOpen(false);
        }
        setTouchStartX(0);
        setTouchTranslateX(0);
    };

    const onTouchMove = (event: TouchEvent<HTMLDivElement>) => {
        throttle(() => {
            setTouchTranslateX(touchStartX - event.changedTouches[0]?.clientX);
        }, 20)();
    };

    const onTouchStart = (event: TouchEvent<HTMLDivElement>) => {
        setTouchStartX(event.changedTouches[0]?.clientX);
    };

    const namespaceSelected = namespaceName !== '';
    const tabIndexProp = isNavigationOpen ? {} : { tabIndex: -1 };
    return (
        <>
            <Navbar
                isNavigationOpen={isNavigationOpen}
                translateX={touchTranslateX > 20 ? touchTranslateX : 0}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
            >
                <CloseButton onClick={() => setNavigationOpen(false)}>
                    <CloseIcon />
                </CloseButton>
                <NavigationContent>
                    <LogoInNavigation src={smartCountrLogoInvert} />
                    <UserProfile>
                        <UserIcon />
                        <LogoutButton
                            {...tabIndexProp}
                            onClick={() => {
                                dispatch(clearToken());
                                localStorage.removeItem('token');
                                history.push(getRoute(RouteEnum.Logout));
                            }}
                        >
                            <FormattedMessage id="logout" />
                        </LogoutButton>
                    </UserProfile>
                    <MenuDivider />
                    <NavigationEntry
                        {...tabIndexProp}
                        onClick={() => setNavigationOpen(false)}
                        to={getRoute(RouteEnum.NamespaceDashboard, { namespaceName })}
                        $isActiveEntry={getRoute(RouteEnum.NamespaceDashboard, { namespaceName }) === activeRoute}
                    >
                        {namespaceName}
                    </NavigationEntry>
                    {namespaceSelected && (
                        <SubNavigationEntries>
                            <SubNavigationEntry
                                {...tabIndexProp}
                                onClick={() => setNavigationOpen(false)}
                                to={getRoute(RouteEnum.RoomOverview, { namespaceName })}
                                $disabled={!namespaceSelected}
                                $isActiveEntry={getRoute(RouteEnum.RoomOverview, { namespaceName }) === activeRoute}
                            >
                                <FormattedMessage id="room.overview" />
                            </SubNavigationEntry>
                            <SubNavigationEntry
                                {...tabIndexProp}
                                onClick={() => setNavigationOpen(false)}
                                to={getRoute(RouteEnum.Configuration, { namespaceName })}
                                $disabled={!namespaceSelected}
                                $isActiveEntry={getRoute(RouteEnum.Configuration, { namespaceName }) === activeRoute}
                            >
                                <FormattedMessage id="config" />
                            </SubNavigationEntry>
                            <SubNavigationEntry
                                onClick={() => setNavigationOpen(false)}
                                to={getRoute(RouteEnum.RoomsReport, { namespaceName })}
                                $disabled={!namespaceSelected}
                                $isActiveEntry={getRoute(RouteEnum.RoomsReport, { namespaceName }) === activeRoute}
                            >
                                <FormattedMessage id="navigation.analysis" />
                            </SubNavigationEntry>
                        </SubNavigationEntries>
                    )}
                    {nonMutatingSort(
                        namespaces.filter((namespace) => namespace.name !== namespaceName),
                        orderByName
                    ).map((namespace, key) => (
                        <NavigationEntry
                            {...tabIndexProp}
                            onClick={() => setNavigationOpen(false)}
                            to={getRoute(RouteEnum.NamespaceDashboard, { namespaceName: namespace.name })}
                            $isActiveEntry={getRoute(RouteEnum.NamespaceDashboard, { namespaceName: namespace.name }) === activeRoute}
                            key={key}
                        >
                            {namespace.name}
                        </NavigationEntry>
                    ))}
                </NavigationContent>
            </Navbar>
            <Overlay onClick={() => setNavigationOpen(!isNavigationOpen)} isNavigationOpen={isNavigationOpen} />
        </>
    );
};

export default Navigation;
