import { ActionType, createAction } from 'typesafe-actions';
import { RoomReports } from '../reducer/roomsReducer';
import { Room, RoomHourReportEntry, RoomUpdateMessage } from '../../models/room';

export const setRooms = createAction('SET_ROOMS')<Room[]>();

export const updateNumberOfPersons = createAction('UPDATE_NUMBER_OF_PERSONS')<RoomUpdateMessage[]>();

export const updateRoomReport = createAction('UPDATE_ROOM_REPORT')<{
    namespaceId: string;
    roomId: string;
    roomReport: RoomHourReportEntry[];
}>();

export const updateRoomReports = createAction('UPDATE_ROOM_REPORTS')<{ namespaceId: string; roomReports: RoomReports }>();

export const getReport = createAction('GET_REPORT')<{ namespaceId: string; type: string; roomId?: string }>();

export type RoomsActions =
    | ActionType<typeof setRooms>
    | ActionType<typeof updateNumberOfPersons>
    | ActionType<typeof updateRoomReports>
    | ActionType<typeof updateRoomReport>
    | ActionType<typeof getReport>;
