import { CounterStateWithAssignedRooms } from '../models/counter';

export const HIDDEN_COUNTER_KEYWORD = '[HIDDEN]';

export const isHiddenName = (name: string): boolean => name.startsWith(HIDDEN_COUNTER_KEYWORD);

export const formatHiddenName = (name: string): string => name.substring(HIDDEN_COUNTER_KEYWORD.length);

export const isHidden = (counter: CounterStateWithAssignedRooms): boolean => isHiddenName(counter.counterState.name);

export const isNotHidden = (counter: CounterStateWithAssignedRooms): boolean => !isHidden(counter);

export const hasHidden = (counters: CounterStateWithAssignedRooms[]): boolean => counters.findIndex(isHidden) !== -1;

// TODO: Get rid of type cast
export const getHidden = (counters: CounterStateWithAssignedRooms[]): CounterStateWithAssignedRooms =>
    counters.find(isHidden) as CounterStateWithAssignedRooms;
