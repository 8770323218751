import { Route, Switch } from 'react-router-dom';
import GuardedRoute from './GuardedRoute/GuardedRoute';
import NamespaceSelection from '../NamespaceSelection/NamespaceSelection';
import Login from '../Login/Login';
import InvalidRoute from '../InvalidRoute/InvalidRoute';
import TrafficLight from '../TrafficLight/TrafficLight';
import DoorDetails from '../DoorDetails/DoorDetails';
import Logout from '../Logout/Logout';
import RoomDetail from '../RoomDetail/RoomDetail';
import RoomOverview from '../RoomOverview/RoomOverview';
import NamespaceDashboard from '../NamespaceDashboard/NamespaceDashboard';
import Configuration from '../Configuration/Configuration';
import AllRoomsReport from '../AllRoomsReport/AllRoomsReport';
import { RouteEnum } from '../../helper/routeHelper';

const Routes = () => (
    <Switch>
        <GuardedRoute exact path={RouteEnum.NamespaceSelection} component={NamespaceSelection} />
        <GuardedRoute path={RouteEnum.TrafficLight} component={TrafficLight} fullscreen />
        <GuardedRoute path={RouteEnum.DoorDetailsWithoutRoom} component={DoorDetails} />
        <GuardedRoute path={RouteEnum.DoorDetails} component={DoorDetails} />
        <GuardedRoute path={RouteEnum.RoomOverview} component={RoomOverview} />
        <GuardedRoute path={RouteEnum.RoomDetail} component={RoomDetail} />
        <GuardedRoute path={RouteEnum.RoomsReport} component={AllRoomsReport} />
        <GuardedRoute path={RouteEnum.NamespaceDashboard} component={NamespaceDashboard} />
        <GuardedRoute path={RouteEnum.Configuration} component={Configuration} />
        <Route path={RouteEnum.Login} component={Login} />
        <Route path={RouteEnum.Logout} component={Logout} />
        <Route component={InvalidRoute} />
    </Switch>
);

export default Routes;
