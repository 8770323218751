import { sendMessageToServer } from '../api/webSocket';
import { ActionType } from 'typesafe-actions';
import { call } from 'redux-saga/effects';
import { getDistancerReport } from '../store/actions/distancerActions';

export function getDistancerReportByNamespaceId(namespaceId: string, distancerId: string) {
    sendMessageToServer(JSON.stringify({ action: 'get-report', type: 'distancer-report', namespaceId, distancerId }));
}

export function* getDistancerReportSaga(action: ActionType<typeof getDistancerReport>) {
    const { namespaceId, distancerId } = action.payload;
    yield call(getDistancerReportByNamespaceId, namespaceId, distancerId);
}
