import { ApplicationState } from '../storeConfig';
import { getCounterStateByName } from './countersSelectors';
import { getRoomByName, getRoomsByRoomIds } from './roomsSelectors';
import { nonMutatingSort } from '../../helper/sortingHelper';
import { EMPTY_ROOM, OUTSIDE_ROOM, Room } from '../../models/room';
import { CounterStateWithAssignedRooms } from '../../models/counter';

export const getRoomsGroupedBySide = (
    state: ApplicationState,
    counterName: string,
    selectedRoomName: string
): { roomsOnSelectedRoomSide: Room[]; roomsOnOppositeSide: Room[] } => {
    const counterState = getCounterStateByName(state, counterName);
    const selectedRoom = getRoomByName(state, selectedRoomName);
    const isFilledFromInValue = counterState.roomIdsFilledFromInValue.some((roomId) => roomId === selectedRoom.id);
    const isFilledFromOutValue = counterState.roomIdsFilledFromOutValue.some((roomId) => roomId === selectedRoom.id);
    if (counterState.roomIdsFilledFromOutValue.length === 0 && counterState.roomIdsFilledFromInValue.length === 0) {
        return { roomsOnSelectedRoomSide: [EMPTY_ROOM], roomsOnOppositeSide: [EMPTY_ROOM] };
    }
    if (isFilledFromInValue) {
        const roomsOnSelectedRoomSide = getRoomsByRoomIds(state, counterState.roomIdsFilledFromInValue);
        const roomsOnOppositeSide =
            counterState.roomIdsFilledFromOutValue.length > 0
                ? getRoomsByRoomIds(state, counterState.roomIdsFilledFromOutValue)
                : [OUTSIDE_ROOM];
        return {
            roomsOnSelectedRoomSide,
            roomsOnOppositeSide,
        };
    }
    if (isFilledFromOutValue) {
        const roomsOnSelectedRoomSide = getRoomsByRoomIds(state, counterState.roomIdsFilledFromOutValue);
        const roomsOnOppositeSide =
            counterState.roomIdsFilledFromInValue.length > 0
                ? getRoomsByRoomIds(state, counterState.roomIdsFilledFromInValue)
                : [OUTSIDE_ROOM];
        return {
            roomsOnSelectedRoomSide,
            roomsOnOppositeSide,
        };
    }
    return {
        roomsOnSelectedRoomSide: [],
        roomsOnOppositeSide: [],
    };
};

export const getRoomsGroupedBySideWithoutSelectedRoom = (
    state: ApplicationState,
    counterName: string
): { roomsOnSelectedRoomSide: Room[]; roomsOnOppositeSide: Room[] } => {
    const counterState = getCounterStateByName(state, counterName);
    const roomsOnSelectedRoomSide =
        counterState.roomIdsFilledFromInValue.length > 0 ? getRoomsByRoomIds(state, counterState.roomIdsFilledFromInValue) : [OUTSIDE_ROOM];
    const roomsOnOppositeSide =
        counterState.roomIdsFilledFromOutValue.length > 0
            ? getRoomsByRoomIds(state, counterState.roomIdsFilledFromOutValue)
            : [OUTSIDE_ROOM];
    return {
        roomsOnSelectedRoomSide,
        roomsOnOppositeSide,
    };
};

const getRoomIdsOrOutSideId = (roomIds: string[]) => (roomIds.length > 0 ? roomIds : [OUTSIDE_ROOM.id]);

export const getCountersWithAssignedRoomsOnOppositeSideOfRoom = (
    state: ApplicationState,
    roomName: string
): CounterStateWithAssignedRooms[] => {
    const { rooms, counters } = state;
    const selectedRoom = rooms.rooms.find((room) => room.name === roomName);
    if (selectedRoom !== undefined) {
        return counters.counterStates
            .map((counter) => {
                const selectedRoomFilledFromInValue = counter.roomIdsFilledFromInValue.some((roomId) => roomId === selectedRoom.id);
                const assignedRoomIds = selectedRoomFilledFromInValue
                    ? getRoomIdsOrOutSideId(counter.roomIdsFilledFromOutValue)
                    : counter.roomIdsFilledFromOutValue.some((roomId) => roomId === selectedRoom.id)
                    ? getRoomIdsOrOutSideId(counter.roomIdsFilledFromInValue)
                    : [];
                const assignedRooms = assignedRoomIds.map((roomId) => rooms.rooms.find((room) => roomId === room.id) || OUTSIDE_ROOM);

                return { counterState: counter, assignedRooms, selectedRoomFilledFromInValue };
            })
            .filter((counterStateWithAssignedRooms) => counterStateWithAssignedRooms.assignedRooms.length > 0);
    }
    return [];
};

export const getAllRooms = (state: ApplicationState): Room[] => {
    return state.rooms.rooms;
};

export const getAllRoomsSorted = (state: ApplicationState, compareFn: (a: Room, b: Room) => number): Room[] => {
    return nonMutatingSort(getAllRooms(state), compareFn);
};

export const getCounterConfigsWithRoomNames = (
    state: ApplicationState
): { name: string; roomsFilledFromInValue: string[]; roomsFilledFromOutValue: string[]; id: string }[] =>
    state.counters.counterStates.map(({ name, roomIdsFilledFromInValue, roomIdsFilledFromOutValue, id }) => {
        const roomsFilledFromInValue = getRoomsByRoomIds(state, roomIdsFilledFromInValue)
            .filter((room) => room !== EMPTY_ROOM)
            .map((room) => room.name);
        const roomsFilledFromOutValue = getRoomsByRoomIds(state, roomIdsFilledFromOutValue)
            .filter((room) => room !== EMPTY_ROOM)
            .map((room) => room.name);
        return { name, roomsFilledFromInValue, roomsFilledFromOutValue, id };
    });
