import { call, put } from 'redux-saga/effects';
import { requestInitClientFromServer } from '../api/webSocket';
import { initClient, setIsConnected } from '../store/actions/authActions';
import { ActionType } from 'typesafe-actions';
import { setInitialNamespaces } from '../store/actions/namespacesActions';

export function* requestInitClientSaga() {
    yield call(requestInitClientFromServer);
}

export function* initClientSaga({ payload }: ActionType<typeof initClient>) {
    yield put(setInitialNamespaces(payload.namespaces));
    yield put(setIsConnected(true));
}
