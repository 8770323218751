import { RoomsActions, setRooms, updateNumberOfPersons, updateRoomReport, updateRoomReports } from '../actions/roomsActions';
import { ActionType, getType } from 'typesafe-actions';
import { setSelectedNamespaceId } from '../actions/namespacesActions';
import { Room, RoomHourReportEntry, RoomUpdateMessage } from '../../models/room';

export interface StateOfRooms {
    rooms: Room[];
    isLoading: boolean;
    roomReports: RoomReports;
}

export type RoomReports = {
    [roomId: string]: RoomHourReportEntry[];
};

export const initialState: StateOfRooms = {
    rooms: [],
    isLoading: true,
    roomReports: {},
};

export const roomsReducer = (
    state = initialState,
    action: RoomsActions | { type: '@@redux/INIT' } | ActionType<typeof setSelectedNamespaceId>
): StateOfRooms => {
    switch (action.type) {
        case getType(setRooms):
            return {
                ...state,
                isLoading: false,
                rooms: action.payload,
            };
        case getType(updateNumberOfPersons): {
            return {
                ...state,
                rooms: state.rooms.map((room: Room) => ({
                    ...room,
                    ...(action.payload.find((roomUpdate: RoomUpdateMessage) => room.id === roomUpdate.id) || {}),
                })),
            };
        }
        case getType(setSelectedNamespaceId):
            return initialState;
        case getType(updateRoomReport): {
            return {
                ...state,
                roomReports: {
                    ...state.roomReports,
                    [action.payload.roomId]: action.payload.roomReport,
                },
            };
        }
        case getType(updateRoomReports): {
            return {
                ...state,
                roomReports: action.payload.roomReports,
            };
        }
        default:
            return state;
    }
};
