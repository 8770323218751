import { Dispatch } from 'redux';
import { requestInitClient, setIsConnected } from '../store/actions/authActions';
import * as websocketModule from './webSocket';
import { handleWebsocketMessage } from './websocketMessageHandler';
import { ChangeCounterStateMessage } from '../models/counter';
import { ConfigFile } from '../models/config';

let webSocket: WebSocket;

export const openWebsocketConnectionWithToken = (locale: string, dispatch: Dispatch, token: string, onError: () => void): void => {
    const queryString = `?Token=${encodeURIComponent(token)}`;
    websocketModule.openWebsocketConnection(locale, dispatch, queryString, onError);
};

export const openWebsocketConnection = (locale: string, dispatch: Dispatch, queryString: string, onError: () => void): void => {
    const url = process.env.REACT_APP_API_URL;

    // Close old websocket if neccesary
    if (webSocket && (webSocket.readyState == webSocket.CONNECTING || webSocket.readyState == webSocket.OPEN)) {
        webSocket.close();
    }
    webSocket = new WebSocket(url + queryString);

    webSocket.onopen = () => {
        /* TODO: Don't request init on token refresh */
        dispatch(requestInitClient());
    };

    webSocket.onerror = (): void => {
        onError();
        dispatch(setIsConnected(false));
    };

    webSocket.onclose = () => {
        dispatch(setIsConnected(false));
    };

    webSocket.onmessage = (event: MessageEvent) => {
        handleWebsocketMessage(locale, dispatch, event);
    };
};

export const requestInitClientFromServer = () => {
    const message = JSON.stringify({ action: 'init-client' });
    websocketModule.sendMessageToServer(message);
};

export const changeSelectedNamespaceId = (selectedNamespaceId: string) => {
    const message = JSON.stringify({ action: 'select-namespace', selectedNamespaceId });
    websocketModule.sendMessageToServer(message);
};

export const changeDoorStatus = (counterId: string, openDoor: boolean, namespaceId: string) => {
    const message = JSON.stringify({ action: openDoor ? 'open-door' : 'close-door', counterId, namespaceId });
    websocketModule.sendMessageToServer(message);
};

export const changeCounterState = (counterState: ChangeCounterStateMessage, namespaceId: string) => {
    const message = JSON.stringify({ action: 'change-counter-state', counterState, namespaceId });
    websocketModule.sendMessageToServer(message);
};

export const resetCounter = (counterId: string) => {
    const message = JSON.stringify({ action: 'reset-counter', counterId });
    websocketModule.sendMessageToServer(message);
};

export const sendMessageToServer = (message: string): void => {
    webSocket.send(message);
};

export const uploadConfigFile = (configFile: ConfigFile, namespaceId: string) => {
    const message = JSON.stringify({ action: 'update-config', configFile, namespaceId });
    websocketModule.sendMessageToServer(message);
};

export const submitCriticalThresholdToServer = (namespaceId: string, criticalThreshold: number) => {
    sendMessageToServer(
        JSON.stringify({
            action: 'set-critical-threshold',
            namespaceId,
            criticalThreshold,
        })
    );
};
