import { ApplicationState } from '../../store/storeConfig';
import { getCriticalThreshold } from '../../store/selector/namespaceSelectors';
import { getRoomReportWithLabelAndLimitForBusinessHours } from '../../helper/roomHelper';
import { Room, RoomReportWithLimit } from '../../models/room';

export type RoomWithReport = {
    room: Room;
    roomReportEntries: RoomReportWithLimit[];
    roomReportLoading: boolean;
};
export type AllRoomsReportSelectorResult = {
    roomReports: RoomWithReport[];
    selectedNamespaceId: string;
    criticalThreshold: number;
    isLoadingRooms: boolean;
    maxValue: number;
};

export const allRoomsReportSelector = (state: ApplicationState) => {
    const reports = state.rooms.roomReports;
    const roomReports: RoomWithReport[] = state.rooms.rooms.map((room) => {
        const roomReportFromState = reports[room.id];
        const roomReportLoading = roomReportFromState === undefined;
        const roomReportEntries = getRoomReportWithLabelAndLimitForBusinessHours(roomReportFromState, room);
        return {
            room,
            roomReportEntries,
            roomReportLoading,
        };
    });
    const allValues = roomReports.flatMap((roomWithReport) =>
        roomWithReport.roomReportEntries.map((entry) => Math.max(entry.limit || 0, entry.maxValue))
    );
    const maxValue = Math.max(...allValues);
    return {
        isLoadingRooms: state.rooms.isLoading,
        roomReports,
        maxValue,
        criticalThreshold: getCriticalThreshold(state),
        selectedNamespaceId: state.namespaces.selectedNamespaceId,
    };
};
