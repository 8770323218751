import { FormEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserCircle } from '@styled-icons/boxicons-solid';
import styled from 'styled-components/macro';
import Button from '../shared/Button/Button';
import SmartCountrLogo from '../../assets/SmartCountr-Logo.png';
import { FormattedMessage } from 'react-intl';
import { getRoute, RouteEnum } from '../../helper/routeHelper';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store/storeConfig';
import { setToken } from '../../store/actions/authActions';

const Logo = styled.img`
    align-self: center;
    justify-self: center;
    width: 209px;
`;

const UserIcon = styled(UserCircle)`
    width: 4rem;
    height: 4rem;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
`;

const LoginForm = styled.form`
    width: 100vw;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        width: 25rem;
    }
    background-color: ${(props) => props.theme.login.formBackgroundColor};
    padding: 2rem;
    box-shadow: 0 0 14px 13px rgba(0, 0, 0, 0.1);
`;

const Page = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: ${(props) => props.theme.login.pageBackgroundColor};
    grid-template-rows: 1fr auto 1fr;
`;

const LoginButton = styled(Button)`
    margin-top: 2rem;
`;

// TODO: REMOVE!!!
const AUTH_DISABLED = true;

function Login({ location }: { location?: { state: { referrer: string } } }) {
    const history = useHistory();

    // TODO: REMOVE!!!
    const dispatch = useDispatch();
    const { keycloak } = AUTH_DISABLED ? { keycloak: { login: () => {} } } : useKeycloak();
    // const { keycloak } = useKeycloak();

    const token = useSelector((state: ApplicationState) => state.auth.token);

    useEffect(() => {
        if (token != '') {
            history.push(location?.state?.referrer || getRoute(RouteEnum.NamespaceSelection));
        }
        // TODO: REMOVE!!!
        else if (AUTH_DISABLED) {
            dispatch(setToken('no_token'));
        }
    }, [token, location]);

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        keycloak.login();
    };

    return (
        <Page>
            <Logo src={SmartCountrLogo} alt="logo" />
            <LoginForm onSubmit={onSubmit}>
                <Header>
                    <h2>
                        <FormattedMessage id="login" />
                    </h2>
                    <UserIcon />
                </Header>
                <LoginButton type="submit">
                    <FormattedMessage id="login.loginButton" />
                </LoginButton>
            </LoginForm>
        </Page>
    );
}

export default Login;
