import { CounterActions, setCounters, setDoorStatus, setInitialCounters, updateCounterState } from '../actions/counterActions';
import { ActionType, getType } from 'typesafe-actions';
import { setSelectedNamespaceId } from '../actions/namespacesActions';
import { CounterState, EMPTY_COUNTER_STATE } from '../../models/counter';
import { CounterConfig } from '../../models/config';

export interface StateOfCounters {
    counterStates: CounterState[];
    isLoading: boolean;
}

export const initialState: StateOfCounters = {
    counterStates: [],
    isLoading: true,
};

export const countersReducer = (
    state = initialState,
    action: CounterActions | { type: '@@redux/INIT' } | ActionType<typeof setSelectedNamespaceId>
): StateOfCounters => {
    switch (action.type) {
        case getType(setInitialCounters): {
            const counterStates = JSON.parse(JSON.stringify(action.payload));
            return {
                ...state,
                counterStates: counterStates.map((counterState: CounterState) => ({
                    ...EMPTY_COUNTER_STATE,
                    ...counterState,
                })),
                isLoading: false,
            };
        }
        case getType(updateCounterState): {
            const counterToUpdate = state.counterStates.find((counter) => counter.id === action.payload.id);
            const countersWithoutCounterToUpdate = state.counterStates.filter((counter) => counter.id !== action.payload.id);
            const { in: counterIn, out, doorOpen, id } = action.payload;
            return {
                ...state,
                counterStates: [
                    ...countersWithoutCounterToUpdate,
                    ...(counterToUpdate ? [{ ...counterToUpdate, in: counterIn, out, doorOpen, id }] : []),
                ],
            };
        }
        case getType(setDoorStatus): {
            const { counterId, doorOpen } = action.payload;
            const selectedCounter = state.counterStates.find((counter) => counter.id === counterId);
            // check only for typescript. A client can not receive a set door status message for a unknown counter
            return selectedCounter
                ? {
                      ...state,
                      counterStates: [
                          ...state.counterStates.filter((counter) => counter.id !== counterId),
                          { ...selectedCounter, doorOpen },
                      ],
                  }
                : state;
        }
        case getType(setSelectedNamespaceId):
            return initialState;
        case getType(setCounters):
            return {
                ...state,
                counterStates: action.payload.map(
                    (counterConfig: CounterConfig): CounterState => ({
                        ...EMPTY_COUNTER_STATE,
                        ...counterConfig,
                    })
                ),
            };
        default:
            return state;
    }
};
