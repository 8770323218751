import { setLocale, SettingsActions } from '../actions/settingsActions';
import { getType } from 'typesafe-actions';

export interface StateOfSettings {
    locale: string;
}

const initialState: StateOfSettings = {
    locale: 'de',
};

export const settingsReducer = (state = initialState, action: SettingsActions | { type: '@@redux/INIT' }) => {
    switch (action.type) {
        case getType(setLocale):
            return { ...state, locale: action.payload };
        default:
            return state;
    }
};
