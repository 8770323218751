import { ApplicationState } from '../storeConfig';
import { EMPTY_ROOM, Room } from '../../models/room';

export const getRoomByName = ({ rooms }: ApplicationState, roomName: string): Room => {
    if (rooms.isLoading) {
        return EMPTY_ROOM;
    } else {
        const room = rooms.rooms.find((room) => room.name === roomName);
        if (!room) {
            return EMPTY_ROOM;
        } else {
            return room;
        }
    }
};

export const getRoomsByRoomIds = (state: ApplicationState, roomIds: string[]): Room[] => {
    const filteredRooms = state.rooms.rooms.filter((room: Room) => roomIds.some((roomId) => room.id === roomId));
    return filteredRooms.length === 0 ? [EMPTY_ROOM] : filteredRooms;
};
