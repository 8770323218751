import styled from 'styled-components/macro';

export const UtilizationGrid = styled.div`
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 1rem;
    line-height: 1.1rem;
`;
export const Number = styled.div`
    text-align: right;
`;
export const DoorContainer = styled.div`
    display: grid;
    align-content: stretch;
    grid-template-rows: max-content max-content;
    grid-gap: 2rem;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-template-columns: max-content max-content max-content;
        grid-template-rows: initial;
        grid-gap: 1rem;
    }
`;

export const Details = styled.div`
    margin-right: 2rem;
`;

export const ChartContainer = styled.div`
    width: 100%;
    @media (min-width: ${(props) => props.theme.responsiveDesign.smartphoneLandscapeWidth}) {
        width: ${(props) => props.theme.roomReport.width}px;
    }
    svg {
        overflow: visible;
    }
`;

export const DoorAndStats = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
`;

export const Container = styled.div`
    display: grid;
    grid-gap: 2rem;
    grid-template-rows: max-content max-content;
    justify-content: stretch;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-template-columns: max-content max-content;
        grid-template-rows: initial;
        justify-content: center;
        grid-gap: 1rem;
    }
`;

export const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 2rem;
    justify-content: stretch;
`;

export const AddPersonButtonContainer = styled.div`
    display: grid;
    grid-gap: 2rem;
    grid-template-rows: max-content max-content;
    justify-content: stretch;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-template-columns: max-content max-content;
        grid-template-rows: initial;
        justify-content: center;
        grid-gap: 1rem;
    }
`;

export const Counters = styled.div`
    display: grid;
    grid-gap: 1rem;
`;
export const AssignedRooms = styled.div`
    display: grid;
`;
export const AssignedRoomTile = styled.div<{ index: number; numberOfRooms: number }>`
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-column: 1;
        grid-row: 1;
        margin-left: ${(props) => props.index * 2}rem;
        opacity: ${(props) => (props.index + 1) / props.numberOfRooms};
        position: ${(props) => (props.index > 0 ? 'absolute' : 'static')};
        z-index: ${(props) => props.index + 1};
        &:hover {
            z-index: 100;
            opacity: 1;
        }
    }
`;
export const CurrentRoom = styled.div`
    align-self: start;
    position: sticky;
    top: 5rem;
`;

export const MobileContainer = styled.div`
    overflow: hidden;
`;

export const DoorTileContainer = styled.div`
    margin-left: 2rem;
    margin-right: 2rem;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        margin: 0;
    }
`;
