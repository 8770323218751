import { DefaultTheme } from 'styled-components/macro';

export const black = '#0d0d0d';
export const white = '#ffffff';
export const lightBlue = '#99CCFF';
export const blue = '#5B88B5';
export const darkBlue = '#0F3358';
export const red = '#ce1f00';
export const darkRed = '#ab1901';
export const green = '#1c9800';
export const lightGray = 'lightgray';
export const veryLightGray = '#F1F1F1';
export const gray = 'gray';
const darkGray = '#0d0d0d';
const lightBrown = '#B5935B';
export const darkBrown = '#694D1F';
export const yellow = '#f1c40f';
export const veryLightYellow = 'rgba(241,196,15,0.21)';
export const warnColor = yellow;
export const errorColor = red;
export const successColor = green;

const tileWidth = '14rem';
const tileHeight = '10rem';
const tileBorderRadius = '2px';

export const tabletPortraitWidth = '768px';
export const tabletLandscapeWidth = '1024px';
export const smartphoneLandscapeWidth = '576px';

export const collapseAnimationDuration = process.env.NODE_ENV === 'test' ? 0 : 500;

export const roomReportWidth = 340;
export const roomReportMobileWidth = 300;

export const theme: DefaultTheme = {
    colors: {
        base: {
            primary: 'rgb(230,230,230)',
            secondary: 'rgb(16,78,130)',
        },
        light: {
            primary: 'rgb(255,255,255)',
            secondary: 'rgb(99,156,191)',
        },
        dark: {
            primary: 'rgb(100,100,100)',
            secondary: 'rgb(17,56,89)',
        },
    },
    responsiveDesign: {
        tabletPortraitWidth,
        tabletLandscapeWidth,
        smartphoneLandscapeWidth,
    },
    trafficLight: {
        red,
        green,
        gray,
        textColor: white,
    },
    footer: black,
    roomTile: {
        backgroundColor: blue,
        activeColor: darkBlue,
        outsideBackgroundColor: lightGray,
        outsideBorder: `1px solid ${white}`,
        outsideTextColor: white,
        width: tileWidth,
        height: tileHeight,
        textColor: white,
        headerFontSize: '1.5rem',
        barchartColor: veryLightGray,
        barchartFillColor: darkBlue,
        barchartLimitReachedColor: red,
        barchartCriticalThresholdColor: yellow,
        borderRadius: tileBorderRadius,
    },
    login: {
        pageBackgroundColor: lightBlue,
        formBackgroundColor: white,
    },
    input: {
        backgroundColor: white,
        borderColor: veryLightGray,
        focusColor: blue,
        invalidColor: red,
    },
    label: {
        fontColor: gray,
    },
    dashboardTile: {
        mobileWidth: 'calc(50% - 0.5rem)',
        width: '10rem',
        height: '8rem',
        borderRadius: tileBorderRadius,
    },
    doorTile: {
        width: tileHeight,
        height: tileHeight,
        backgroundColor: lightBrown,
        hoverBackgroundColor: darkBrown,
        hoverTextColor: white,
        arrowColor: white,
        arrowTextColor: black,
        stopColor: red,
        textColor: white,
        borderRadius: tileBorderRadius,
    },
    animations: {
        transitionDuration: '200ms',
        easingFunction: 'ease-out',
    },
    loadingSpinner: {
        dotSize: '1rem',
        color: 'rgba(255, 255, 255, .5)',
        highlightColor: 'white',
    },
    navigation: {
        backgroundColor: darkBlue,
        color: white,
        activeColor: lightBlue,
        disabledColor: gray,
        hoverColor: lightBlue,
        dividerColor: lightBlue,
        focusColor: lightBlue,
    },
    burgerNavigationMobile: {
        backgroundColor: white,
        color: black,
    },
    button: {
        backgroundColor: black,
        disabledBackgroundColor: veryLightGray,
        textColor: white,
        disabledTextColor: darkGray,
        backgroundColorHover: darkBlue,
    },
    skeleton: {
        backgroundColor: veryLightGray,
    },
    pagination: {
        activeElementColor: lightBlue,
        inactiveElementColor: veryLightGray,
    },
    notification: {
        successBackgroundColor: successColor,
        errorBackgroundColor: errorColor,
    },
    namespace: {
        backgroundColor: blue,
        color: white,
        backgroundHoverColor: darkBlue,
    },
    roomReport: {
        width: roomReportWidth,
        mobileWidth: roomReportMobileWidth,
    },
};
