import { FormattedMessage, useIntl } from 'react-intl';
import { HeaderCell, LoadingCell, Row, Table, TextCell } from '../shared/Table/Table.styles';
import DotLoadingSpinner from '../shared/LoadingSpinner/DotLoadingSpinner';
import { nonMutatingSort, orderAlphabetically } from '../../helper/sortingHelper';
import ResetCounterButton from './ResetCounterButton';
import { ViewHide } from '@styled-icons/zondicons';
import styled from 'styled-components/macro';
import { formatHiddenName, isHiddenName } from '../../helper/counterHelper';

const HiddenIcon = styled(ViewHide)`
    width: 1rem;
    height: 1rem;
`;

const CounterConfigs = ({
    headerId,
    noDataMessageId,
    counterConfigsWithRoomNames,
    isLoading = false,
}: {
    headerId: string;
    noDataMessageId: string;
    counterConfigsWithRoomNames: { name: string; roomsFilledFromInValue: string[]; roomsFilledFromOutValue: string[]; id: string }[];
    isLoading?: boolean;
}) => {
    const intl = useIntl();
    return (
        <div>
            <h3>
                <FormattedMessage id={headerId} />
            </h3>
            {counterConfigsWithRoomNames.length === 0 && !isLoading ? (
                <FormattedMessage id={noDataMessageId} />
            ) : (
                <Table>
                    <thead>
                        <tr>
                            <HeaderCell>
                                <FormattedMessage id="config.roomsFilledFromInValue" />
                            </HeaderCell>
                            <HeaderCell>
                                <FormattedMessage id="config.counterName" />
                            </HeaderCell>
                            <HeaderCell>
                                <FormattedMessage id="config.roomsFilledFromOutValue" />
                            </HeaderCell>
                            <HeaderCell>
                                <FormattedMessage id="config.resetCounter" />
                            </HeaderCell>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <Row>
                                <td colSpan={3}>
                                    <LoadingCell>
                                        <DotLoadingSpinner />
                                    </LoadingCell>
                                </td>
                            </Row>
                        ) : (
                            counterConfigsWithRoomNames.map(({ name, roomsFilledFromInValue, roomsFilledFromOutValue, id }, index) => (
                                <Row key={index} data-testid={name}>
                                    <TextCell>
                                        {roomsFilledFromInValue.length > 0 ? (
                                            nonMutatingSort(roomsFilledFromInValue, orderAlphabetically).join(', ')
                                        ) : (
                                            <FormattedMessage id="roomTile.outside" />
                                        )}
                                    </TextCell>
                                    <TextCell>
                                        {isHiddenName(name) ? (
                                            <>
                                                <HiddenIcon title={intl.formatMessage({ id: 'icon.hiddenCounter' })} />
                                                &nbsp; {formatHiddenName(name)}
                                            </>
                                        ) : (
                                            name
                                        )}
                                    </TextCell>
                                    <TextCell>
                                        {roomsFilledFromOutValue.length > 0 ? (
                                            nonMutatingSort(roomsFilledFromOutValue, orderAlphabetically).join(', ')
                                        ) : (
                                            <FormattedMessage id="roomTile.outside" />
                                        )}
                                    </TextCell>
                                    <TextCell>
                                        <ResetCounterButton counterId={id} />
                                    </TextCell>
                                </Row>
                            ))
                        )}
                    </tbody>
                </Table>
            )}
        </div>
    );
};

export default CounterConfigs;
