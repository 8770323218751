import { getType } from 'typesafe-actions';
import { DistancerActions, DistancerReport, updateDistancerReport, updateDistancerState } from '../actions/distancerActions';
import { DistancerMessage } from '../../models/distancer';

export type StateOfDistancers = {
    distancerStates: DistancerStates;
    distancerReports: DistancerReports;
};

export type DistancerState = DistancerMessage[];

export type DistancerStates = {
    [distancerId: string]: DistancerState;
};

export type DistancerReports = {
    [distancerId: string]: DistancerReport;
};

export const initialStateOfDistancers: StateOfDistancers = {
    distancerReports: {},
    distancerStates: { 'aws-console-test': [] },
};

export const distancerReducer = (state: StateOfDistancers = initialStateOfDistancers, action: DistancerActions): StateOfDistancers => {
    switch (action.type) {
        case getType(updateDistancerState): {
            console.log('Updating distancer state:', action.payload);
            return {
                ...state,
                distancerStates: {
                    ...state.distancerStates,
                    [action.payload.distancerId]: action.payload.distancerState,
                },
            };
        }
        case getType(updateDistancerReport): {
            return {
                ...state,
                distancerReports: {
                    ...state.distancerReports,
                    [action.payload.distancerId]: action.payload.distancerReport,
                },
            };
        }
        default:
            return state;
    }
};
