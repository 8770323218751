import {
    NamespacesActions,
    resetSelectedNamespaceId,
    setCriticalThreshold,
    setInitialNamespaces,
    setSelectedNamespaceId,
    submitCriticalThreshold,
    submitCriticalThresholdFailed,
    submitCriticalThresholdSucceeded,
} from '../actions/namespacesActions';
import { getType } from 'typesafe-actions';
import { Namespace } from '../../models/namespace';

export interface StateOfNamespaces {
    namespaces: Namespace[];
    selectedNamespaceId: string;
    isSubmittingCriticalThreshold: boolean;
}

const initialState: StateOfNamespaces = {
    namespaces: [],
    selectedNamespaceId: '',
    isSubmittingCriticalThreshold: false,
};

export const namespacesReducer = (state = initialState, action: NamespacesActions | { type: '@@redux/INIT' }) => {
    switch (action.type) {
        case getType(setInitialNamespaces):
            return { ...state, namespaces: action.payload, selectedNamespaceId: initialState.selectedNamespaceId };
        case getType(setSelectedNamespaceId):
            return { ...state, selectedNamespaceId: action.payload };
        case getType(resetSelectedNamespaceId):
            return { ...state, selectedNamespaceId: initialState.selectedNamespaceId };
        case getType(setCriticalThreshold): {
            const { namespaceId, criticalThreshold } = action.payload;
            return {
                ...state,
                namespaces: state.namespaces.map((namespace) =>
                    namespace.id === namespaceId ? { ...namespace, criticalThreshold } : namespace
                ),
            };
        }
        case getType(submitCriticalThreshold): {
            const { namespaceId, criticalThreshold } = action.payload;
            if (state.selectedNamespaceId === action.payload.namespaceId) {
                return {
                    ...state,
                    isSubmittingCriticalThreshold: true,
                    namespaces: state.namespaces.map((namespace) =>
                        namespace.id === namespaceId ? { ...namespace, criticalThreshold } : namespace
                    ),
                };
            }
            return state;
        }
        case getType(submitCriticalThresholdSucceeded): {
            if (state.selectedNamespaceId === action.payload.namespaceId) {
                return {
                    ...state,
                    isSubmittingCriticalThreshold: false,
                };
            }
            return state;
        }
        case getType(submitCriticalThresholdFailed): {
            if (state.selectedNamespaceId === action.payload.namespaceId) {
                return {
                    ...state,
                    isSubmittingCriticalThreshold: false,
                };
            }
            return state;
        }
        default:
            return state;
    }
};
