import styled, { css } from 'styled-components/macro';
import DotLoadingSpinner from '../shared/LoadingSpinner/DotLoadingSpinner';
import { HandStop } from '@styled-icons/zondicons';
import { Stop } from '@styled-icons/octicons';
import { DoorOpen } from '@styled-icons/boxicons-solid';

const IconSize = css`
    width: 1.5rem;
    height: 1.5rem;
`;

export const SceletonIcon = styled.div`
    ${IconSize}
    border-radius: 0.75rem;
    background-color: ${(props) => props.theme.skeleton.backgroundColor};
`;

export const SkeletonBlock = styled.span`
    width: 100px;
    display: inline-block;
    border-radius: 0.5rem;
    background-color: ${(props) => props.theme.skeleton.backgroundColor};
`;

export const IconWithText = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`;

export const DoorOpenIcon = styled(DoorOpen)`
    ${IconSize}
`;

export const HandStopIcon = styled(HandStop)`
    ${IconSize}
    width: 1.5rem;
    height: 1.5rem;
`;

export const StopIcon = styled(Stop)`
    ${IconSize}
`;

export const RoomInfo = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: max-content max-content max-content;
    justify-content: stretch;

    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-template-columns: max-content max-content max-content;
        grid-template-rows: initial;
        align-items: center;
        justify-content: start;
    }
`;

export const Loading = styled(DotLoadingSpinner)`
    margin: auto;
`;

export const DesktopRoomsContainer = styled.div`
    display: grid;
    grid-gap: 1rem;
`;

export const MobileRoomsContainer = styled.div`
    overflow: hidden;
`;

export const DoorTileWithTrafficLights = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 2rem auto 2rem;

    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-template-rows: 2rem max-content 2rem;
        grid-template-columns: initial;
        justify-items: center;
    }
`;

export const TrafficLightIcon = styled.img`
    width: 2rem;
`;

export const PositionCloseDoorButton = styled.div`
    grid-row: 3;
    margin: 0 2rem 1.5rem 2rem;
    display: grid;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-column: 1 / span 3;
        justify-self: center;
        grid-row: initial;
        margin: 0;
    }
`;
