import { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { validateConfigFile } from '../../roomConfigValidator/roomConfigValidator';
import { ConfigFile } from '../../models/config';

const RoomConfigLabel = styled.label<{ disabled: boolean }>`
    background-color: ${(props) => (props.disabled ? props.theme.button.disabledBackgroundColor : props.theme.button.backgroundColor)};
    padding: 0.5rem;
    border: none;
    color: ${(props) => (props.disabled ? props.theme.button.disabledTextColor : props.theme.button.textColor)};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    text-align: center;
    line-height: 1rem;
`;

export interface ConfigFileUploadProps {
    updateConfigFile: (fileName: string, configFile: ConfigFile) => void;
    invalidConfigFile?: () => void;
    disabled: boolean;
}

const ConfigFileUpload = ({ updateConfigFile, invalidConfigFile = () => {}, disabled }: ConfigFileUploadProps) => {
    const selectConfigFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const fileReader = new FileReader();
            const fileName: string = e.target.files[0].name;
            fileReader.readAsText(e.target.files[0], 'UTF-8');
            fileReader.onload = (fileProgress) => {
                if (fileProgress.target && fileProgress.target.result) {
                    try {
                        const configFileJson = fileProgress.target.result.toString();
                        const configFile = JSON.parse(configFileJson);
                        const isValid = validateConfigFile(configFile);
                        isValid ? updateConfigFile(fileName, configFile) : invalidConfigFile();
                    } catch (error) {
                        invalidConfigFile();
                    }
                } else {
                    invalidConfigFile();
                }
            };
        }
    };

    return (
        <RoomConfigLabel disabled={disabled}>
            <FormattedMessage id="selectConfigFile" />
            <input
                style={{ display: 'none' }}
                type="file"
                accept="application/JSON"
                onChange={selectConfigFile}
                data-testid="config-file-input"
                disabled={disabled}
            />
        </RoomConfigLabel>
    );
};

export default ConfigFileUpload;
