import styled, { ThemeProvider } from 'styled-components/macro';
import { GlobalStyles } from './styles/globalStyles';
import { theme } from './styles/theme';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store/storeConfig';
import { IntlProvider } from 'react-intl';
import { loadLocaleData } from '../translations/helper';
import Routes from './Routes/Routes';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

SwiperCore.use([Pagination]);

const StyledToastContainer = styled(ToastContainer)`
    .Toastify__toast--success {
        background-color: ${(props) => props.theme.notification.successBackgroundColor};
    }
    .Toastify__toast--error {
        background-color: ${(props) => props.theme.notification.errorBackgroundColor};
    }
`;

function App() {
    const { locale } = useSelector((state: ApplicationState) => ({
        locale: state.settings.locale,
    }));

    return (
        <IntlProvider messages={loadLocaleData(locale)} locale={locale}>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <Router>
                    <Routes />
                </Router>
                <StyledToastContainer position="bottom-right" />
            </ThemeProvider>
        </IntlProvider>
    );
}

export default App;
