import styled from 'styled-components/macro';

const Loading = styled.div<{ color?: string; highlightColor?: string; dotSize?: string }>`
    background-color: ${(props) => props.color || props.theme.loadingSpinner.color};
    border-radius: 50%;
    color: ${(props) => props.color || props.theme.loadingSpinner.color};
    height: ${(props) => props.dotSize || props.theme.loadingSpinner.dotSize};
    width: ${(props) => props.dotSize || props.theme.loadingSpinner.dotSize};
    font-size: ${(props) => props.dotSize || props.theme.loadingSpinner.dotSize};
    animation: dotFlashing 1s 0.5s infinite linear alternate;
    position: relative;
    &::before,
    &::after {
        background-color: ${(props) => props.color || props.theme.loadingSpinner.color};
        border-radius: 50%;
        color: ${(props) => props.color || props.theme.loadingSpinner.color};
        height: ${(props) => props.dotSize || props.theme.loadingSpinner.dotSize};
        width: ${(props) => props.dotSize || props.theme.loadingSpinner.dotSize};
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
    }
    &::before {
        animation: dotFlashing 1s infinite alternate;
        left: -1.5em;
    }
    &::after {
        animation: dotFlashing 1s 1s infinite alternate;
        left: 1.5em;
    }

    @keyframes dotFlashing {
        0% {
            background-color: ${(props) => props.color || props.theme.loadingSpinner.color};
        }
        100% {
            background-color: ${(props) => props.highlightColor || props.theme.loadingSpinner.highlightColor};
        }
    }
`;

const DotLoadingSpinner = ({ color, highlightColor, dotSize }: { color?: string; highlightColor?: string; dotSize?: string }) => (
    <Loading color={color} highlightColor={highlightColor} dotSize={dotSize} />
);

export default DotLoadingSpinner;
