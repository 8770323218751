import {
    DomainPropType,
    VictoryArea,
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryLabel,
    VictoryLine,
    VictoryScatter,
    VictoryVoronoiContainer,
} from 'victory';
import React, { Fragment } from 'react';
import {
    black,
    darkBlue,
    gray,
    lightBlue,
    red,
    roomReportMobileWidth,
    roomReportWidth,
    smartphoneLandscapeWidth,
    veryLightGray,
    veryLightYellow,
    white,
    yellow,
} from '../styles/theme';
import { ChartContainer } from './RoomDetail.styles';
import { BUSINESS_END, BUSINESS_START } from '../../helper/roomHelper';
import { useIntl } from 'react-intl';
import PersonsPerHourChartSummaryTooltip from './PersonsPerHourChartSummaryTooltip';
import useMedia from '../../hooks/useMedia';
import { RoomReportWithLimit } from '../../models/room';

type PersonsPerHourChartProps = {
    roomReportEntries: RoomReportWithLimit[];
    roomReportLoading: boolean;
    actualNumberOfPersons?: number;
    limit?: number;
    criticalThreshold: number;
    maxValue?: number;
};

const PersonsPerHourChart = ({
    roomReportEntries,
    roomReportLoading,
    actualNumberOfPersons,
    limit = 0,
    criticalThreshold,
    maxValue,
}: PersonsPerHourChartProps) => {
    const intl = useIntl();
    const domain: { domain?: DomainPropType } =
        maxValue && !roomReportLoading ? { domain: { x: [BUSINESS_START, BUSINESS_END], y: [0, maxValue] } } : {};

    const actualHour = new Date().getHours();
    const isSmartPhoneLandscape = useMedia(`(min-width: ${smartphoneLandscapeWidth})`);
    const chartWidth = (isSmartPhoneLandscape ? roomReportWidth : roomReportMobileWidth) * 2;

    return (
        <ChartContainer>
            <VictoryChart
                width={chartWidth}
                height={220}
                domainPadding={16}
                padding={{ top: 32, right: 0, bottom: 50, left: 0 }}
                {...domain}
                containerComponent={
                    <VictoryVoronoiContainer
                        portalZIndex={1001}
                        voronoiDimension="x"
                        mouseFollowTooltips={false}
                        labels={({ datum }) => (datum.label !== undefined ? `${datum.labelPrefix}\t${datum.label}` : '')}
                        labelComponent={roomReportLoading ? <Fragment /> : <PersonsPerHourChartSummaryTooltip />}
                    />
                }
            >
                <VictoryAxis
                    crossAxis={true}
                    style={{
                        tickLabels: { fontSize: 24, fontFamily: 'Mulish' },
                        ticks: { stroke: 'grey', size: 10 } as React.CSSProperties,
                    }}
                    tickValues={roomReportEntries.filter((entry) => entry.hour % 3 === 0).map((entry) => entry.hour)}
                    tickFormat={(tick) => tick + ':00'}
                />
                {!roomReportLoading && limit > 0 && (
                    <VictoryArea
                        labelComponent={<Fragment />}
                        style={{
                            data: {
                                fill: veryLightYellow,
                                strokeDasharray: 8,
                                stroke: roomReportLoading ? veryLightGray : gray,
                            },
                        }}
                        data={roomReportEntries.map((roomReport) => ({
                            ...roomReport,
                            x: roomReport.hour,
                            y: roomReport.limit,
                            y0: ((roomReport.limit || 0) * criticalThreshold) / 100,
                            label: `${roomReport.limit}`,
                            labelPrefix: intl.formatMessage({ id: 'personPerHoursChart.limitTooltip' }),
                        }))}
                    />
                )}
                <VictoryBar
                    labelComponent={<Fragment />}
                    style={{
                        data: {
                            fill: ({ datum }) => {
                                const isAboveThreshold = datum.limit && datum.maxValue / datum.limit >= criticalThreshold / 100;
                                return roomReportLoading
                                    ? veryLightGray
                                    : datum.limit
                                    ? datum.maxValue >= datum.limit
                                        ? red
                                        : isAboveThreshold
                                        ? yellow
                                        : lightBlue
                                    : lightBlue;
                            },
                        },
                    }}
                    data={roomReportEntries.map((roomReport) => ({
                        ...roomReport,
                        labelPrefix: intl.formatMessage({ id: 'personPerHoursChart.maxTooltip' }),
                        label:
                            roomReport.hour <= actualHour
                                ? roomReport.maxValue.toLocaleString(undefined, { maximumFractionDigits: 0 })
                                : undefined,
                    }))}
                    barRatio={1}
                    x="hour"
                    y="maxValue"
                />
                {!roomReportLoading && (
                    <VictoryLine
                        labelComponent={<Fragment />}
                        style={{
                            data: {
                                stroke: darkBlue,
                                strokeWidth: 4,
                            },
                        }}
                        data={roomReportEntries
                            .filter((report) => report.hour <= actualHour)
                            .map((roomReport) => ({
                                ...roomReport,
                                label: roomReport.avgValue.toLocaleString(undefined, { maximumFractionDigits: 1 }),
                                labelPrefix: intl.formatMessage({ id: 'personPerHoursChart.avgTooltip' }),
                            }))}
                        x="hour"
                        y="avgValue"
                    />
                )}
                {actualNumberOfPersons !== undefined && !roomReportLoading && (
                    <VictoryScatter
                        style={{
                            data: { fill: white, stroke: black, strokeWidth: 2 },
                            labels: {
                                fontSize: 24,
                                fontFamily: "'Mulish', 'Montserrat', sans-serif",
                            },
                        }}
                        size={7}
                        data={[
                            {
                                x: actualHour,
                                y: actualNumberOfPersons,
                                label: actualNumberOfPersons,
                                labelPrefix: intl.formatMessage({ id: 'personPerHoursChart.currentTooltip' }),
                            },
                        ]}
                        labelComponent={<VictoryLabel dx={20} textAnchor="start" verticalAnchor="middle" />}
                    />
                )}
            </VictoryChart>
        </ChartContainer>
    );
};

export default PersonsPerHourChart;
