import { setCriticalThreshold, submitCriticalThresholdFailed, submitCriticalThresholdSucceeded } from '../store/actions/namespacesActions';
import { initClient } from '../store/actions/authActions';
import { setCounters, setDoorStatus, setInitialCounters, updateCounterState } from '../store/actions/counterActions';
import { Dispatch } from 'redux';
import { setRooms, updateNumberOfPersons, updateRoomReport, updateRoomReports } from '../store/actions/roomsActions';
import { toast } from 'react-toastify';
import { loadLocaleData } from '../translations/helper';
import { createIntl } from 'react-intl';
import { RoomReports } from '../store/reducer/roomsReducer';
import { getDistancerReport, updateDistancerReport, updateDistancerState } from '../store/actions/distancerActions';
import { NamespaceHourReportMessageEntry, Room, RoomHourReportEntry, RoomHourReportMessageEntry, RoomMessage } from '../models/room';
import { CounterState, CounterStateMessage, UpdateCounterState, UpdateCounterStateMessage } from '../models/counter';
import { DoorStatusMessage } from '../models/door';

export const handleWebsocketMessage = (locale: string, dispatch: Dispatch, event: { data: string }): void => {
    const messages = loadLocaleData(locale);
    const intl = createIntl({
        messages,
        locale,
    });
    const data = JSON.parse(event.data);
    switch (data.type) {
        case 'INIT_CLIENT': {
            dispatch(initClient({ namespaces: JSON.parse(data.namespaces) }));
            break;
        }
        case 'COUNTER_STATE_CHANGED': {
            const counterState = mapUpdateCounterStateMessageToUpdateCounterState(data.counterState);
            dispatch(updateCounterState(counterState));
            break;
        }
        case 'SET_DOOR_STATUS': {
            const { counterId, doorOpen }: DoorStatusMessage = data;
            dispatch(setDoorStatus({ counterId, doorOpen }));
            break;
        }
        case 'SELECT_NAMESPACE_SUCCEEDED': {
            const counters: CounterState[] = data.counters.map(mapCounterStateMessageToCounterState);
            const rooms = data.rooms.map(mapRoomMessageToRoom);
            dispatch(setInitialCounters(counters));
            dispatch(setRooms(rooms));
            if (data.criticalThreshold) {
                dispatch(
                    setCriticalThreshold({
                        namespaceId: data.namespaceId,
                        criticalThreshold: data.criticalThreshold,
                    })
                );
            }
            break;
        }
        case 'UPDATE_CONFIG_INVALID_MESSAGE':
            throw new Error('Server replied with UPDATE_CONFIG_INVALID_MESSAGE');
        case 'UPDATE_CONFIG_SUCCEEDED':
            // Es existieren zwei ähnliche Nachrichten:
            // UPDATE_CONFIG_SUCCEEDED und CONFIG_UPDATED
            // - CONFIG_UPDATED ist der Broadcast des Servers wenn die Konfiguration geändert wurde
            // - UPDATE_CONFIG_SUCCEEDED ist die Antwort des Servers an den Client, der die Änderung angestoßen hat,
            //   dass Nachricht im Server angekommen ist.
            toast.success(intl.formatMessage({ id: 'notification.updateConfigSucceeded' }));
            break;
        case 'UPDATE_CONFIG_FAILED':
            toast.error(intl.formatMessage({ id: 'notification.updateConfigFailed' }));
            break;
        case 'SET_DOOR_STATUS_SUCCEEDED':
            toast.success(intl.formatMessage({ id: 'notification.setDoorStatusSucceeded' }));
            break;
        case 'RESET_COUNTER_SUCCEEDED':
            toast.success(intl.formatMessage({ id: 'notification.resetCounterSucceeded' }));
            break;
        case 'SET_DOOR_STATUS_FAILED':
            toast.error(intl.formatMessage({ id: 'notification.setDoorStatusFailed' }));
            break;
        case 'SET_DOOR_STATUS_INVALID_MESSAGE':
            throw new Error('Server replied with SET_DOOR_STATUS_INVALID_MESSAGE');
        case 'CONFIG_UPDATED': {
            const { rooms: roomMessages, counters: counterMessages } = data;
            const rooms = mapRoomMessagesToRooms(roomMessages);
            const counters = mapCounterStateMessagesToCounterStates(counterMessages);
            dispatch(setRooms(rooms));
            dispatch(setCounters(counters));
            break;
        }
        case 'UPDATE_ROOMS': {
            const { rooms: roomUpdateMessages } = data;
            dispatch(updateNumberOfPersons(roomUpdateMessages));
            break;
        }
        case 'GET_REPORT_SUCCEEDED': {
            if (data.roomId) {
                dispatch(
                    updateRoomReport({
                        namespaceId: data.namespaceId,
                        roomId: data.roomId,
                        roomReport: data.result.map(mapRoomHourReportMessageEntryToRoomHourReportEntry),
                    })
                );
            } else if (data.distancerId) {
                dispatch(
                    updateDistancerReport({
                        distancerId: data.distancerId,
                        distancerReport: data.result.map(mapRoomHourReportMessageEntryToRoomHourReportEntry),
                    })
                );
            } else {
                dispatch(
                    updateRoomReports({
                        namespaceId: data.namespaceId,
                        roomReports: roomHourReportMessageEntryToRoomReports(data.result),
                    })
                );
            }
            break;
        }
        case 'GET_REPORT_FAILED': {
            toast.error(intl.formatMessage({ id: 'notification.getReportFailed' }));
            break;
        }
        case 'SET_CRITICAL_THRESHOLD_SUCCEEDED':
            dispatch(submitCriticalThresholdSucceeded({ namespaceId: data.namespaceId }));
            toast.success(intl.formatMessage({ id: 'notification.criticalThresholdSucceeded' }));
            break;
        case 'SET_CRITICAL_THRESHOLD_FAILED':
            dispatch(submitCriticalThresholdFailed({ namespaceId: data.namespaceId }));
            toast.error(intl.formatMessage({ id: 'notification.criticalThresholdFailed' }));
            break;
        case 'CRITICAL_THRESHOLD_CHANGED':
            dispatch(setCriticalThreshold({ namespaceId: data.namespaceId, criticalThreshold: data.criticalThreshold }));
            break;
        case 'DISTANCER_STATE_CHANGED': {
            dispatch(updateDistancerState(data));
            // TODO maybe throttle
            dispatch(getDistancerReport({ namespaceId: data.namespaceId, distancerId: data.distancerId }));
            break;
        }
        default:
            console.error('unknown websocket message', data);
    }
};

export const mapCounterStateMessageToCounterState = ({
    id,
    counterIn,
    counterOut,
    counterName,
    doorOpen,
    roomIdsFilledFromInValue,
    roomIdsFilledFromOutValue,
}: CounterStateMessage): CounterState => ({
    id,
    name: counterName,
    in: counterIn,
    out: counterOut,
    doorOpen,
    roomIdsFilledFromInValue,
    roomIdsFilledFromOutValue,
});

export const mapCounterStateMessagesToCounterStates = (counterStateMessages: CounterStateMessage[]): CounterState[] =>
    counterStateMessages.map(mapCounterStateMessageToCounterState);

export const mapUpdateCounterStateMessageToUpdateCounterState = ({
    id,
    counterIn,
    counterOut,
    doorOpen,
}: UpdateCounterStateMessage): UpdateCounterState => ({
    id,
    in: counterIn,
    out: counterOut,
    doorOpen,
});

export const mapRoomMessageToRoom = ({ id, roomName, roomLimit, numberOfPersons }: RoomMessage): Room => ({
    id,
    name: roomName,
    limit: roomLimit,
    numberOfPersons,
});

export const mapRoomMessagesToRooms = (roomMessages: RoomMessage[]): Room[] => roomMessages.map(mapRoomMessageToRoom);

export const mapRoomHourReportMessageEntryToRoomHourReportEntry = (message: RoomHourReportMessageEntry): RoomHourReportEntry => ({
    hour: new Date(message.timestamp).getHours(),
    maxValue: Number(message.maxValue),
    avgValue: Number(message.avgValue),
});

export const roomHourReportMessageEntryToRoomReports = (messages: NamespaceHourReportMessageEntry[]): RoomReports => {
    return messages.reduce<RoomReports>((acc: RoomReports, current: NamespaceHourReportMessageEntry) => {
        return {
            ...acc,
            [current.roomId]: [...(acc[current.roomId] || []), mapRoomHourReportMessageEntryToRoomHourReportEntry(current)],
        };
    }, {});
};
