import styled, { css } from 'styled-components/macro';
import { DisplayMode } from './RoomTile';
import Button from '../Button/Button';

const TileStyles = css<{ displayMode: DisplayMode }>`
    background-color: ${(props) => props.theme.roomTile.backgroundColor};
    width: 100%;
    height: ${(props) => (props.displayMode === DisplayMode.Tile ? '8rem' : '3rem')};
    justify-content: space-between;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        width: ${(props) => props.theme.roomTile.width};
        height: ${(props) => props.theme.roomTile.height};
        justify-content: space-between;
    }
    padding: ${(props) => (props.displayMode === DisplayMode.Tile ? '1rem' : '0.5rem')};
    color: ${(props) => props.theme.roomTile.textColor};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: ${(props) => props.theme.roomTile.borderRadius};
    border: 4px solid ${(props) => props.theme.roomTile.backgroundColor};
`;

export const EmptyTile = styled.div`
    ${TileStyles}
    background-color: ${(props) => props.theme.skeleton.backgroundColor};
    border: 4px solid ${(props) => props.theme.skeleton.backgroundColor};
`;

export const TileButton = styled(Button)`
    ${TileStyles}
    cursor: pointer;
    &:hover {
        background-color: ${(props) => props.theme.roomTile.activeColor};
        border: 4px solid ${(props) => props.theme.roomTile.activeColor};
    }
`;

export const Tile = styled.div`
    ${TileStyles}
    border: 4px solid ${(props) => props.theme.roomTile.activeColor};
    &:hover {
        background-color: ${(props) => props.theme.roomTile.backgroundColor};
        border: 4px solid ${(props) => props.theme.roomTile.activeColor};
    }
`;

export const OutsideTile = styled.div`
    ${TileStyles}
    background-color: ${(props) => props.theme.roomTile.outsideBackgroundColor};
    border: ${(props) => props.theme.roomTile.outsideBorder};
    color: ${(props) => props.theme.roomTile.outsideTextColor};
`;

export const RoomName = styled.div<{ displayMode: DisplayMode }>`
    overflow: hidden;
    hyphens: auto;
    text-align: left;

    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        font-size: ${(props) => props.theme.roomTile.headerFontSize};
    }
`;

export const Barchart = styled.div`
    background-color: ${(props) => props.theme.roomTile.barchartColor};
    height: 1.5rem;
    display: grid;
    overflow: hidden;
    width: 100%;
    border: 1px solid ${(props) => props.theme.roomTile.textColor};
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        height: 2rem;
    }
`;

export const Bar = styled.div<{ $percentage: number; $criticalThreshold: number }>`
    background-color: ${(props) =>
        props.$percentage < props.$criticalThreshold
            ? props.theme.roomTile.barchartFillColor
            : props.$percentage >= 100
            ? props.theme.roomTile.barchartLimitReachedColor
            : props.theme.roomTile.barchartCriticalThresholdColor};
    width: ${(props) => `${props.$percentage}%`};
    height: 1.5rem;
    grid-column: 1;
    grid-row: 1;

    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        height: 2rem;
    }
`;

export const Label = styled.div<{ percentage: number }>`
    grid-column: 1;
    grid-row: 1;
    justify-self: right;
    align-self: center;
    padding-right: 0.5rem;
    mix-blend-mode: ${(props) => (props.percentage < 100 ? 'difference' : 'unset')};
    color: ${(props) => props.theme.roomTile.textColor};
`;
