import { useHistory } from 'react-router-dom';
import React, { useRef } from 'react';
import { useDecodedParams } from '../../hooks/useDecodedParams';
import UrlParamValidator from '../UrlParamValidator/UrlParamValidator';
import { useSelector } from 'react-redux';
import RoomTile from '../shared/RoomTile/RoomTile';
import { FormattedMessage } from 'react-intl';
import {
    CheckIcon,
    ClosedDoorIcons,
    ClosedDoorTile,
    DashboardTile,
    DashboardTiles,
    DoorTiles,
    EmptyClosedDoorTile,
    HandStopIcon,
    Header2,
    RoomTiles,
    StopIcon,
    TileMessage,
    TileValue,
} from './NamespaceDashboard.styles';
import { namespaceDashboardSelector } from './NamespaceDashboard.selector';
import { getRoute, RouteEnum } from '../../helper/routeHelper';
import { EMPTY_ROOM } from '../../models/room';

const NamespaceDashboard = () => {
    const history = useHistory();
    const { namespaceName }: { namespaceName: string } = useDecodedParams();
    const criticalRoomsRef = useRef<HTMLHeadingElement>(null);
    const closedDoorsRef = useRef<HTMLHeadingElement>(null);

    const { criticalRooms, isLoadingRooms, roomCount, isLoadingDoors, closedDoors, criticalThreshold, atLeastOneRoomFull } = useSelector(
        namespaceDashboardSelector
    );

    return (
        <>
            <h1>{namespaceName}</h1>
            <UrlParamValidator namespaceName={namespaceName}>
                <>
                    <DashboardTiles>
                        <DashboardTile status="ok" onClick={() => history.push(getRoute(RouteEnum.RoomOverview, { namespaceName }))}>
                            {!isLoadingRooms && (
                                <>
                                    <span>
                                        <FormattedMessage id="namespaces.rooms" />
                                    </span>
                                    <TileValue>{roomCount}</TileValue>
                                </>
                            )}
                        </DashboardTile>
                        <DashboardTile
                            status={
                                !isLoadingRooms && atLeastOneRoomFull
                                    ? 'error'
                                    : !isLoadingRooms && criticalRooms.length > 0
                                    ? 'warn'
                                    : 'ok'
                            }
                            onClick={() => criticalRoomsRef?.current?.scrollIntoView()}
                            data-testid="criticalRooms-tile"
                        >
                            {!isLoadingRooms && (
                                <>
                                    <span>
                                        <FormattedMessage id="namespaces.criticalRooms" data-testid="criticalRooms-tile-content" />
                                    </span>
                                    <TileValue>{criticalRooms.length}</TileValue>
                                </>
                            )}
                        </DashboardTile>
                        <DashboardTile
                            status={!isLoadingDoors && closedDoors.length > 0 ? 'warn' : 'ok'}
                            onClick={() => closedDoorsRef?.current?.scrollIntoView()}
                            data-testid="closedDoors-tile"
                        >
                            {!isLoadingDoors && (
                                <>
                                    <span>
                                        <FormattedMessage id="namespaces.closedDoorsShort" data-testid="closeDoors-tile-content" />
                                    </span>
                                    <TileValue>{closedDoors.length}</TileValue>
                                </>
                            )}
                        </DashboardTile>
                    </DashboardTiles>
                    <Header2 ref={criticalRoomsRef}>
                        <FormattedMessage id="namespaces.criticalRooms" />
                    </Header2>
                    <p>
                        <FormattedMessage id="namespaces.criticalDescription" values={{ criticalThreshold }} />
                    </p>
                    <RoomTiles>
                        {isLoadingRooms ? (
                            <RoomTile room={EMPTY_ROOM} criticalThreshold={criticalThreshold} />
                        ) : criticalRooms.length > 0 ? (
                            criticalRooms.map((room, index) => <RoomTile key={index} room={room} criticalThreshold={criticalThreshold} />)
                        ) : (
                            <TileMessage>
                                <CheckIcon />
                                <span>
                                    <FormattedMessage id="namespaces.noRoomReachedLimit" />
                                </span>
                            </TileMessage>
                        )}
                    </RoomTiles>
                    <Header2 ref={closedDoorsRef}>
                        <FormattedMessage id="namespaces.closedDoors" />
                    </Header2>
                    <DoorTiles>
                        {isLoadingDoors ? (
                            <EmptyClosedDoorTile data-testid="empty-door-tile" />
                        ) : closedDoors.length > 0 ? (
                            closedDoors.map((closedDoor, index) => (
                                <ClosedDoorTile
                                    key={index}
                                    onClick={() =>
                                        history.push(
                                            getRoute(RouteEnum.DoorDetailsWithoutRoom, {
                                                namespaceName,
                                                counterName: closedDoor.name,
                                            })
                                        )
                                    }
                                >
                                    <ClosedDoorIcons>
                                        {closedDoor.assignedToRoomWithLimitReached && <StopIcon />}
                                        {closedDoor.manuallyClosed && <HandStopIcon />}
                                    </ClosedDoorIcons>
                                    <span>{closedDoor.name}</span>
                                </ClosedDoorTile>
                            ))
                        ) : (
                            <TileMessage>
                                <CheckIcon />
                                <span>
                                    <FormattedMessage id="namespaces.noDoorClosed" />
                                </span>
                            </TileMessage>
                        )}
                    </DoorTiles>
                </>
            </UrlParamValidator>
        </>
    );
};
export default NamespaceDashboard;
