import { useRouteMatch } from 'react-router-dom';
import { useDecodedParams } from './useDecodedParams';
import { useIntl } from 'react-intl';
import { getRoute, RouteEnum } from '../helper/routeHelper';

export const useNavigationEntries = () => {
    const match = useRouteMatch();
    const { namespaceName, roomName, counterName } = useDecodedParams();
    const intl = useIntl();

    const namespaceDashboard = {
        text: namespaceName,
        path: getRoute(RouteEnum.NamespaceDashboard, { namespaceName }),
    };
    const roomOverview = {
        text: intl.formatMessage({ id: 'room.overview' }),
        path: getRoute(RouteEnum.RoomOverview, { namespaceName }),
    };
    const roomDetail = {
        text: intl.formatMessage({ id: 'room.title' }, { roomName }),
        path: getRoute(RouteEnum.RoomDetail, { namespaceName, roomName }),
    };

    switch (match.path) {
        case RouteEnum.NamespaceDashboard:
            return [{ text: namespaceName }];
        case RouteEnum.RoomOverview:
            return [namespaceDashboard, { text: intl.formatMessage({ id: 'room.overview' }) }];
        case RouteEnum.RoomDetail:
            return [namespaceDashboard, roomOverview, { text: intl.formatMessage({ id: 'room.title' }, { roomName }) }];
        case RouteEnum.DoorDetailsWithoutRoom:
            return [namespaceDashboard, { text: intl.formatMessage({ id: 'door.title' }, { counterName }) }];
        case RouteEnum.DoorDetails:
            return [namespaceDashboard, roomOverview, roomDetail, { text: intl.formatMessage({ id: 'door.title' }, { counterName }) }];
        case RouteEnum.Configuration:
            return [namespaceDashboard, { text: intl.formatMessage({ id: 'config' }) }];
        case RouteEnum.RoomsReport:
            return [namespaceDashboard, { text: intl.formatMessage({ id: 'navigation.analysis' }) }];
        default:
            return [];
    }
};
