export enum RouteEnum {
    NamespaceSelection = '/',
    TrafficLight = '/traffic-light/:namespaceName/:roomName/:counterName',
    DoorDetailsWithoutRoom = '/monitoring/:namespaceName/door/:counterName',
    DoorDetails = '/monitoring/:namespaceName/:roomName/:counterName',
    RoomOverview = '/monitoring/:namespaceName/room-overview',
    RoomDetail = '/monitoring/:namespaceName/:roomName',
    NamespaceDashboard = '/monitoring/:namespaceName',
    Login = '/login',
    Logout = '/logout',
    Configuration = '/settings/:namespaceName',
    RoomsReport = '/reporting/:namespaceName/room-overview',
}

export const getRoute = (page: RouteEnum, params: { [routeParam: string]: string } = {}): string => {
    const pathWithPlaceholder = page.valueOf();
    return Object.entries(params).reduce(
        (previousValue, [routeParam, routeValue]) => previousValue.replace(`:${routeParam}`, encodeURIComponent(routeValue)),
        pathWithPlaceholder
    );
};
