/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { useTokenAndOpenWebsocketConnectionIfNecessary } from '../../../hooks/useTokenAndOpenWebsocketConnectionIfNecessary';
import Page from '../../shared/Page/Page';

const GuardedRoute = ({
    component: Component,
    exact = false,
    path,
    fullscreen = false,
}: {
    component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
    exact?: boolean;
    path: string;
    fullscreen?: boolean;
}) => {
    /* TODO: Cleanup control flow and use isAuthenticated or similar istead of comparing tokens */
    const token = useTokenAndOpenWebsocketConnectionIfNecessary();

    return (
        <Route
            exact={exact}
            path={path}
            render={(props) => {
                if (token !== '') {
                    return fullscreen ? <Component {...props} /> : <Page component={Component} props={props} path={path} />;
                }
                // eslint-disable-next-line react/prop-types
                return <Redirect to={{ pathname: '/login', state: { referrer: props.location.pathname } }} />;
            }}
        />
    );
};

export default GuardedRoute;
