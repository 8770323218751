import styled, { css } from 'styled-components/macro';
import Button from '../shared/Button/Button';
import { black, errorColor, veryLightGray, warnColor, white } from '../styles/theme';
import { Stop } from '@styled-icons/octicons';
import { Check } from '@styled-icons/boxicons-regular';
import { HandStop } from '@styled-icons/zondicons';

export const TileMessage = styled.div`
    width: ${(props) => props.theme.roomTile.width};
    height: ${(props) => props.theme.roomTile.height};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${veryLightGray};
`;
export const TileValue = styled.div`
    font-weight: 300;
    font-size: 1.5rem;

    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        font-size: 2.5rem;
    }
`;
export const DashboardTile = styled(Button)<{ status: 'ok' | 'warn' | 'error' }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.status === 'error' ? errorColor : props.status === 'warn' ? warnColor : veryLightGray)};
    color: ${(props) => (props.status === 'error' ? white : black)};
    border-radius: ${(props) => props.theme.dashboardTile.borderRadius};

    &:focus {
        outline: none;
        background-color: ${(props) => (props.status === 'error' ? errorColor : props.status === 'warn' ? warnColor : veryLightGray)};
    }

    &:hover:not(:disabled) {
        background-color: ${(props) => (props.status === 'error' ? errorColor : props.status === 'warn' ? warnColor : veryLightGray)};
    }
    transition: background-color 300ms ease-out;
    width: 100%;
    height: ${(props) => props.theme.dashboardTile.height};
`;
const TilesStyle = css`
    display: grid;
    grid-gap: 1rem;
    margin-top: 2rem;
    justify-content: stretch;
`;
export const DashboardTiles = styled.div`
    ${TilesStyle}
    grid-template-columns: repeat(auto-fit, ${(props) => props.theme.dashboardTile.mobileWidth});
    @media (min-width: ${(props) => props.theme.responsiveDesign.smartphoneLandscapeWidth}) {
        grid-template-columns: repeat(auto-fit, ${(props) => props.theme.dashboardTile.width});
    }
`;
export const RoomTiles = styled.div`
    ${TilesStyle}
    grid-template-columns: repeat(auto-fit, ${(props) => props.theme.dashboardTile.mobileWidth});
    @media (min-width: ${(props) => props.theme.responsiveDesign.smartphoneLandscapeWidth}) {
        grid-template-columns: repeat(auto-fit, ${(props) => props.theme.roomTile.width});
    }
`;
export const DoorTiles = styled.div`
    ${TilesStyle}
    grid-template-columns: repeat(auto-fit, ${(props) => props.theme.dashboardTile.mobileWidth});
    @media (min-width: ${(props) => props.theme.responsiveDesign.smartphoneLandscapeWidth}) {
        grid-template-columns: repeat(auto-fit, ${(props) => props.theme.doorTile.width});
    }
`;
export const ClosedDoorTile = styled(Button)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.doorTile.backgroundColor};
    color: ${(props) => props.theme.doorTile.textColor};
    text-align: center;
    border-radius: ${(props) => props.theme.doorTile.borderRadius};

    &:focus {
        background-color: ${(props) => props.theme.doorTile.hoverBackgroundColor};
        color: ${(props) => props.theme.doorTile.hoverTextColor};
    }

    &:hover:not(:disabled) {
        background-color: ${(props) => props.theme.doorTile.hoverBackgroundColor};
        color: ${(props) => props.theme.doorTile.hoverTextColor};
    }

    width: 100%;
    height: ${(props) => props.theme.dashboardTile.height};

    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        width: ${(props) => props.theme.doorTile.width};
        height: ${(props) => props.theme.doorTile.height};
    }
`;
export const EmptyClosedDoorTile = styled(Button)`
    height: ${(props) => props.theme.doorTile.height};
    width: ${(props) => props.theme.doorTile.width};
    background-color: ${(props) => props.theme.skeleton.backgroundColor};
    border-radius: ${(props) => props.theme.doorTile.borderRadius};

    &:hover:not(:disabled) {
        background-color: ${(props) => props.theme.skeleton.backgroundColor};
    }
`;
const IconSize = css`
    width: 2rem;
    height: 2rem;
`;
export const CheckIcon = styled(Check)`
    ${IconSize}
`;
export const HandStopIcon = styled(HandStop)`
    ${IconSize}
    margin-bottom: 1rem;
`;
export const StopIcon = styled(Stop)`
    ${IconSize}
    margin-bottom: 1rem;
`;
export const Header2 = styled.h2`
    scroll-margin: 4rem;
`;

export const ClosedDoorIcons = styled.div`
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 0.5rem;
`;
