import styled, { css } from 'styled-components/macro';

const TileStyle = css<{ isTileLoading: boolean }>`
    width: 100%;
    display: grid;
    height: 6rem;
    grid-template-rows: 1fr auto 1fr;
    grid-template-columns: 3rem 1fr 3rem;

    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        width: ${(props) => props.theme.doorTile.width};
        grid-template-columns: 1fr auto 1fr;
        grid-template-rows: 3rem 1fr 3rem;
        height: ${(props) => props.theme.doorTile.height};
    }
    grid-row-gap: 0.2rem;
    align-items: center;
    justify-items: center;
    background-color: ${(props) => (props.isTileLoading ? props.theme.skeleton.backgroundColor : props.theme.doorTile.backgroundColor)};
    padding: 0.2rem 0.5rem;
    border: 4px solid ${(props) => (props.isTileLoading ? props.theme.skeleton.backgroundColor : props.theme.doorTile.backgroundColor)};
    border-radius: ${(props) => props.theme.doorTile.borderRadius};
`;
const TileHoverStyle = css`
    &:hover {
        outline: none;
        background-color: ${(props) => props.theme.doorTile.hoverBackgroundColor};
        color: ${(props) => props.theme.doorTile.hoverTextColor};
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        border: 4px solid ${(props) => props.theme.doorTile.hoverBackgroundColor};
    }
`;
export const TileButton = styled.button<{ isTileLoading: boolean }>`
    ${TileStyle}
    ${(props) => !props.isTileLoading && TileHoverStyle}
`;
export const Tile = styled.div<{ isTileLoading: boolean }>`
    ${TileStyle}

    border: 4px solid ${(props) =>
        props.isTileLoading ? props.theme.skeleton.backgroundColor : props.theme.doorTile.hoverBackgroundColor};
`;
const counterLabelBackground = `
    background-color: white;
    border-radius: 50%;
    min-width: 1.5rem;
    padding: 0.25rem;
    text-align: center;
`;
export const CounterOut = styled.div`
    ${counterLabelBackground}
    grid-column: 1;
    grid-row: 2;
    line-height: 1rem;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-row: 1;
        grid-column: 2;
    }
    color: ${(props) => props.theme.doorTile.arrowTextColor};
`;
export const CounterIn = styled.div`
    ${counterLabelBackground}
    grid-column: 3;
    grid-row: 2;
    line-height: 1rem;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-row: 3;
        grid-column: 2;
    }
    color: ${(props) => props.theme.doorTile.arrowTextColor};
`;
export const BackgroundOut = styled.div<{ height: number }>`
    background-color: ${(props) => props.theme.doorTile.arrowColor};
    grid-column: 1;
    grid-row: 1 / span 3;
    height: 100%;
    width: ${(props) => props.height}rem;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-row: 1;
        grid-column: 1 / span 3;
        height: ${(props) => props.height}rem;
        width: 100%;
    }
`;
export const BackgroundIn = styled.div<{ height: number }>`
    grid-column: 3;
    grid-row: 1 / span 3;
    height: 100%;
    width: ${(props) => props.height}rem;
    background-color: ${(props) => props.theme.doorTile.arrowColor};
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-row: 3;
        grid-column: 1 / span 3;
        height: ${(props) => props.height}rem;
        width: 100%;
    }
`;
export const ArrowRight = styled.div<{ height: number; isHovered: boolean }>`
    grid-column: 1;
    grid-row: 3;
    border-left: ${(props) => props.height}rem solid transparent;
    border-right: ${(props) => props.height}rem solid transparent;
    border-top: ${(props) => props.height}rem solid ${(props) => props.theme.doorTile.arrowColor};
    align-self: end;
    background-color: ${(props) => (props.isHovered ? props.theme.doorTile.hoverBackgroundColor : props.theme.doorTile.backgroundColor)};
    width: 0;
    height: 0;

    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-row: 1;
        grid-column: 3;
        border-top: ${(props) => props.height}rem solid transparent;
        border-bottom: ${(props) => props.height}rem solid transparent;
        border-right: none;
        border-left: ${(props) => props.height}rem solid ${(props) => props.theme.doorTile.arrowColor};
        justify-self: right;
        align-self: initial;
    }
`;

export const ArrowLeft = styled.div<{ height: number; isHovered: boolean }>`
    grid-column: 3;
    grid-row: 1;
    border-left: ${(props) => props.height}rem solid transparent;
    border-right: ${(props) => props.height}rem solid transparent;
    border-bottom: ${(props) => props.height}rem solid ${(props) => props.theme.doorTile.arrowColor};
    align-self: baseline;

    width: 0;
    height: 0;
    background-color: ${(props) => (props.isHovered ? props.theme.doorTile.hoverBackgroundColor : props.theme.doorTile.backgroundColor)};
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-row: 3;
        grid-column: 1;
        border-top: ${(props) => props.height}rem solid transparent;
        border-bottom: ${(props) => props.height}rem solid transparent;
        border-right: ${(props) => props.height}rem solid ${(props) => props.theme.doorTile.arrowColor};
        border-left: none;
        justify-self: left;
        align-self: initial;
    }
`;
export const StopRight = styled.div<{ height: number }>`
    background-color: ${(props) => props.theme.doorTile.stopColor};
    border: 2px solid ${(props) => props.theme.doorTile.arrowColor};
    grid-column: 1;
    grid-row: 3;
    height: ${(props) => (props.height * 2) / 3}rem;
    width: ${(props) => props.height * 2}rem;
    align-self: end;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-row: 1;
        grid-column: 3;
        width: ${(props) => (props.height * 2) / 3}rem;
        height: ${(props) => props.height * 2}rem;
        justify-self: right;
        align-self: initial;
    }
`;
export const StopLeft = styled.div<{ height: number }>`
    background-color: ${(props) => props.theme.doorTile.stopColor};
    border: 2px solid ${(props) => props.theme.doorTile.arrowColor};
    grid-column: 3;
    grid-row: 1;
    height: ${(props) => (props.height * 2) / 3}rem;
    width: ${(props) => props.height * 2}rem;
    align-self: baseline;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-row: 3;
        grid-column: 1;
        width: ${(props) => (props.height * 2) / 3}rem;
        height: ${(props) => props.height * 2}rem;
        justify-self: left;
        align-self: initial;
    }
`;
export const CounterName = styled.div`
    grid-column: 2;
    grid-row: 1 / span 3;
    align-self: center;
    justify-self: center;
    font-size: 1rem;
    color: ${(props) => props.theme.doorTile.textColor};
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-row: 2;
        grid-column: 1 / span 3;
    }
`;
