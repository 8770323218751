import { useEffect, useState } from 'react';

const emptyFunction = () => {};

export const mockMediaQueryList: MediaQueryList = {
    media: '',
    matches: false,
    onchange: emptyFunction,
    addListener: emptyFunction,
    removeListener: emptyFunction,
    addEventListener: emptyFunction,
    removeEventListener: emptyFunction,
    dispatchEvent: (_: Event) => true,
};

const useMedia = (query: string, defaultState = false) => {
    const [state, setState] = useState(defaultState);

    useEffect(() => {
        let mounted = true;
        const mediaQueryList: MediaQueryList = typeof window === 'undefined' ? mockMediaQueryList : window.matchMedia(query);

        const onChange = () => {
            if (!mounted) {
                return;
            }

            setState(Boolean(mediaQueryList.matches));
        };

        mediaQueryList.addEventListener('change', onChange);
        setState(mediaQueryList.matches);

        return () => {
            mounted = false;
            mediaQueryList.removeEventListener('change', onChange);
        };
    }, [query]);

    return state;
};

export default useMedia;
