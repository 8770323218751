import { css } from 'styled-components/macro';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Tile = css`
    width: 80%;
    height: 5rem;
    border-radius: 2.5rem;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    hyphens: auto;
    margin: 1rem;
    padding: 0.5rem;
    text-align: center;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        border-radius: 50%;
        height: 10rem;
        width: 10rem;
    }
`;
export const NamespaceTile = styled(Link)`
    ${Tile}
    background-color: ${(props) => props.theme.namespace.backgroundColor};
    color: ${(props) => props.theme.namespace.color};
    transition: 200ms all ease-out;
    &:hover {
        background-color: ${(props) => props.theme.namespace.backgroundHoverColor};
        transform: scale(1.1);
    }
`;
export const EmptyNamespaceTile = styled.div`
    ${Tile}
    background-color: ${(props) => props.theme.skeleton.backgroundColor};
`;
export const Namespaces = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;
