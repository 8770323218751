import styled from 'styled-components/macro';

export const Pagination = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 0.75rem;
`;

export const Bubble = styled.div<{ isActive: boolean }>`
    background-color: ${(props) =>
        props.isActive ? props.theme.pagination.activeElementColor : props.theme.pagination.inactiveElementColor};
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    margin: 0.125rem;
`;
