import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getAllRoomsSorted } from '../../store/selector/overlappingSelectors';
import { ApplicationState } from '../../store/storeConfig';
import RoomTile from '../shared/RoomTile/RoomTile';
import styled from 'styled-components/macro';
import { useDecodedParams } from '../../hooks/useDecodedParams';
import UrlParamValidator from '../UrlParamValidator/UrlParamValidator';
import React from 'react';
import { getCriticalThreshold } from '../../store/selector/namespaceSelectors';
import { orderByName } from '../../helper/sortingHelper';
import { EMPTY_ROOM } from '../../models/room';

const RoomTiles = styled.div`
    display: grid;
    grid-gap: 1rem;
    margin-top: 2rem;
    justify-content: stretch;

    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        grid-template-columns: repeat(auto-fit, ${(props) => props.theme.roomTile.width});
    }
`;

const RoomOverview = () => {
    const { rooms, isLoading, criticalThreshold } = useSelector((state: ApplicationState) => ({
        rooms: getAllRoomsSorted(state, orderByName),
        isLoading: state.counters.isLoading,
        criticalThreshold: getCriticalThreshold(state),
    }));
    const { namespaceName }: { namespaceName: string } = useDecodedParams();

    return (
        <>
            <h1>
                <FormattedMessage id="room.overview" />
            </h1>
            <UrlParamValidator namespaceName={namespaceName}>
                {isLoading ? (
                    <RoomTiles>
                        <RoomTile room={EMPTY_ROOM} criticalThreshold={criticalThreshold} />
                    </RoomTiles>
                ) : rooms.length > 0 ? (
                    <RoomTiles>
                        {rooms.map((room, index) => (
                            <RoomTile room={room} key={`room-${index}`} criticalThreshold={criticalThreshold} />
                        ))}
                    </RoomTiles>
                ) : (
                    <p>
                        <FormattedMessage id="room.noRoomsConfigured" />
                    </p>
                )}
            </UrlParamValidator>
        </>
    );
};

export default RoomOverview;
