import { AuthActions, clearToken, setIsConnected, setToken } from '../actions/authActions';
import { getType } from 'typesafe-actions';

export interface StateOfAuth {
    token: string;
    isConnected: boolean;
}

const initialState: StateOfAuth = {
    token: localStorage.getItem('token') || '',
    isConnected: false,
};

export const authReducer = (state = initialState, action: AuthActions | { type: '@@redux/INIT' }) => {
    switch (action.type) {
        case getType(setToken):
            return { ...state, token: action.payload };
        case getType(clearToken):
            return { ...state, token: '' };
        case getType(setIsConnected):
            return { ...state, isConnected: action.payload };
        default:
            return state;
    }
};
