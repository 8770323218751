import { sendMessageToServer } from '../api/webSocket';
import { ActionType } from 'typesafe-actions';
import { getReport } from '../store/actions/roomsActions';
import { call, select } from 'redux-saga/effects';
import { getSelectedNamespaceId } from '../store/selector/namespaceSelectors';

export function getReportByNamespaceId(type: string, namespaceId: string, roomId?: string) {
    sendMessageToServer(JSON.stringify({ action: 'get-report', type, namespaceId, roomId }));
}

export function* getReportSaga(action: ActionType<typeof getReport>) {
    const { type, namespaceId, roomId } = action.payload;
    yield call(getReportByNamespaceId, type, namespaceId, roomId);
}

export function* getReportAfterNumberOfPersonsChanged() {
    const selectedNamespaceId = yield select(getSelectedNamespaceId);
    yield call(getReportByNamespaceId, 'room-reports', selectedNamespaceId);
}
