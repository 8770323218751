import {
    ArrowLeft,
    ArrowRight,
    BackgroundIn,
    BackgroundOut,
    CounterIn,
    CounterName,
    CounterOut,
    StopLeft,
    StopRight,
} from './DoorTile.styles';
import { CounterState } from '../../../models/counter';

export const DoorTileContent = ({
    counter,
    isHovered,
    isLoading,
    maxNumberOfPersons = 0,
    hasAnyConnectedRoomReachedLimit = false,
    selectedRoomFilledFromInValue,
}: {
    counter: CounterState;
    isHovered: boolean;
    isLoading: boolean;
    maxNumberOfPersons?: number;
    hasAnyConnectedRoomReachedLimit?: boolean;
    selectedRoomFilledFromInValue: boolean;
}) => {
    const counterInfoToDisplay = selectedRoomFilledFromInValue
        ? counter
        : {
              ...counter,
              in: counter.out,
              out: counter.in,
          };
    const ratioDenominator = Math.max(counterInfoToDisplay.out, counterInfoToDisplay.in, maxNumberOfPersons);
    const ratioIn = ratioDenominator > 0 ? counterInfoToDisplay.in / ratioDenominator : 0;
    const ratioOut = ratioDenominator > 0 ? counterInfoToDisplay.out / ratioDenominator : 0;
    const outHeight = Math.max(isLoading ? 1.5 : ratioOut * 1.5, 0.1);
    const inHeight = Math.max(isLoading ? 1.5 : ratioIn * 1.5, 0.1);

    return (
        <>
            <BackgroundOut height={outHeight} />
            {!isLoading && (
                <>
                    {counter.doorOpen && !hasAnyConnectedRoomReachedLimit ? (
                        <ArrowRight height={Math.max(outHeight, 0.4)} isHovered={isHovered} data-testid="arrow-right" />
                    ) : (
                        <StopRight data-testid="stop-right" height={Math.max(outHeight, 0.8)} />
                    )}
                    <CounterOut>{counterInfoToDisplay.out}</CounterOut>
                </>
            )}

            <CounterName>{counterInfoToDisplay.name}</CounterName>
            <BackgroundIn height={inHeight} />
            {!isLoading && (
                <>
                    {counter.doorOpen && !hasAnyConnectedRoomReachedLimit ? (
                        <ArrowLeft height={Math.max(inHeight, 0.4)} isHovered={isHovered} data-testid="arrow-left" />
                    ) : (
                        <StopLeft data-testid="stop-left" height={Math.max(inHeight, 0.8)} />
                    )}
                    <CounterIn>{counterInfoToDisplay.in}</CounterIn>
                </>
            )}
        </>
    );
};
