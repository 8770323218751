import { takeEvery, takeLatest, throttle } from 'redux-saga/effects';
import { getReportAfterNumberOfPersonsChanged, getReportSaga } from './report.saga';
import { initClientSaga, requestInitClientSaga } from './initClient.saga';
import { selectNamespaceId } from './namespace.saga';
import { getType } from 'typesafe-actions';
import { setSelectedNamespaceId } from '../store/actions/namespacesActions';
import { initClient, requestInitClient } from '../store/actions/authActions';
import { getReport, updateNumberOfPersons } from '../store/actions/roomsActions';
import { getDistancerReport } from '../store/actions/distancerActions';
import { getDistancerReportSaga } from './distancerReport.saga';

export default function* rootSaga() {
    yield takeEvery(getType(getReport), getReportSaga);

    yield takeEvery(getType(getDistancerReport), getDistancerReportSaga);

    yield takeLatest(getType(requestInitClient), requestInitClientSaga);
    yield takeLatest(getType(initClient), initClientSaga);
    yield takeLatest(getType(setSelectedNamespaceId), selectNamespaceId);

    yield throttle(10000, getType(updateNumberOfPersons), getReportAfterNumberOfPersonsChanged);
}
