import styled from 'styled-components/macro';
import { lightGray } from '../styles/theme';
import { usePreventWheel } from '../../hooks/usePreventWheel';

const StyledLimitInput = styled.input`
    border: 1px solid ${lightGray};
    border-radius: 2px;
    text-align: right;
    -moz-appearance: textfield;
    appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0.125rem;
    }
`;

export type LimitInputProps = {
    value?: number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    dataTestId: string;
    disabled: boolean;
    autoFocus: boolean;
};

export const LimitInput = ({ value, onChange, dataTestId, disabled, autoFocus }: LimitInputProps) => {
    const preventWheel = usePreventWheel();
    return (
        <StyledLimitInput
            data-testid={dataTestId}
            ref={preventWheel}
            type="number"
            value={value || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange && onChange(event);
            }}
            disabled={disabled}
            autoFocus={autoFocus}
        />
    );
};
