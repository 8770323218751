import { Room } from './room';

export interface CounterState {
    id: string;
    name: string;
    in: number;
    out: number;
    doorOpen: boolean;
    roomIdsFilledFromInValue: string[];
    roomIdsFilledFromOutValue: string[];
}

export interface CounterStateWithAssignedRooms {
    counterState: CounterState;
    assignedRooms: Room[];
    selectedRoomFilledFromInValue: boolean;
}

export interface UpdateCounterState {
    id: string;
    in: number;
    out: number;
    doorOpen: boolean;
}

export interface UpdateCounterStateMessage {
    id: string;
    counterIn: number;
    counterOut: number;
    counterVersion: string;
    counterTimestamp: string;
    doorOpen: boolean;
}

export interface ChangeCounterStateMessage {
    id: string;
    counterIn: number;
    counterOut: number;
    counterDiff: number;
    counterVersion: number;
    counterTimestamp: number;
}

export interface CounterStateMessage {
    id: string;
    counterName: string;
    counterIn: number;
    counterOut: number;
    counterVersion: number;
    counterTimestamp: string;
    doorOpen: boolean;
    roomIdsFilledFromInValue: string[];
    roomIdsFilledFromOutValue: string[];
}

export const EMPTY_COUNTER_STATE: CounterState = {
    id: 'EMPTY_COUNTER_ID',
    name: '',
    in: 0,
    out: 0,
    doorOpen: true,
    roomIdsFilledFromInValue: [],
    roomIdsFilledFromOutValue: [],
};
export const isCounterLoading = (counter: CounterState) => counter.id === EMPTY_COUNTER_STATE.id;
