import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store/storeConfig';
import { useEffect } from 'react';
import { setSelectedNamespaceId } from '../store/actions/namespacesActions';
import { useDecodedParams } from './useDecodedParams';

export const useNamespaceFromRoute = () => {
    const dispatch = useDispatch();
    const { namespaces, token, selectedNamespaceId, isConnected } = useSelector((state: ApplicationState) => ({
        namespaces: state.namespaces.namespaces,
        selectedNamespaceId: state.namespaces.selectedNamespaceId,
        token: state.auth.token,
        isConnected: state.auth.isConnected,
    }));
    const { namespaceName }: { namespaceName: string | undefined } = useDecodedParams();
    useEffect(() => {
        if (isConnected && namespaceName !== undefined) {
            const selectedNamespace = namespaces.find(
                (namespace) => namespace.name.trim().toLowerCase() === namespaceName.trim().toLowerCase()
            );
            if (selectedNamespace !== undefined && selectedNamespace?.id !== selectedNamespaceId) {
                dispatch(setSelectedNamespaceId(selectedNamespace.id));
            }
        }
    }, [dispatch, token, namespaceName, namespaces, selectedNamespaceId, isConnected]);
};
