/* eslint-disable @typescript-eslint/no-explicit-any */
import Navigation from '../../Routes/GuardedRoute/Navigation';
import NavigationTrigger from '../../Routes/GuardedRoute/NavigationTrigger';
import { PageContent, PageContentContainer } from './Page.styles';
import React, { ComponentType, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import BreadcrumbNavigation from '../BreadcrumbNavigation/BreadcrumbNavigation';
import { useNavigationEntries } from '../../../hooks/useNavigationEntries';
import { useNamespaceFromRoute } from '../../../hooks/useNamespaceFromRoute';
import { useIntl } from 'react-intl';
import { useDecodedParams } from '../../../hooks/useDecodedParams';

type PageProps = {
    component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
    props: RouteComponentProps<any>;
    path: string;
};

export const TITLE_PREFIX = 'SmartCountr - ';

export const Page = ({ component: Component, props, path }: PageProps) => {
    const [isNavigationOpen, setNavigationOpen] = useState(false);
    const navigationEntries = useNavigationEntries();
    useNamespaceFromRoute();

    const { namespaceName, roomName, counterName } = useDecodedParams();

    const intl = useIntl();

    useEffect(() => {
        const title = intl.formatMessage({ id: 'pageTitle.' + path }, { namespaceName, roomName, counterName });
        document.title = TITLE_PREFIX + title;
    }, [intl, path, namespaceName, roomName, counterName]);

    return (
        <div>
            <Navigation setNavigationOpen={setNavigationOpen} isNavigationOpen={isNavigationOpen} />
            <PageContentContainer>
                <NavigationTrigger setNavigationOpen={setNavigationOpen} isNavigationOpen={isNavigationOpen} />
                <PageContent>
                    <BreadcrumbNavigation navigationEntries={navigationEntries} />
                    <Component {...props} />
                </PageContent>
            </PageContentContainer>
        </div>
    );
};

export default Page;
