import 'eventsource/lib/eventsource-polyfill';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store/storeConfig';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import { AuthClientError, AuthClientEvent } from '@react-keycloak/core';

import { setToken } from './store/actions/authActions';

const onKeyCloakEvent = (event: AuthClientEvent, error?: AuthClientError) => {
    if (!error && (event == 'onReady' || event == 'onAuthRefreshSuccess') && keycloak.token) {
        store.dispatch(setToken(keycloak.token));
    }
};

// TODO: REMOVE!!!
const AUTH_DISABLED = true;

if (AUTH_DISABLED) {
    ReactDOM.render(
        <StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </StrictMode>,
        document.getElementById('root')
    );
} else {
    ReactDOM.render(
        <StrictMode>
            <ReactKeycloakProvider authClient={keycloak} initOptions={{ onLoad: 'login-required' }} onEvent={onKeyCloakEvent}>
                <Provider store={store}>
                    <App />
                </Provider>
            </ReactKeycloakProvider>
        </StrictMode>,
        document.getElementById('root')
    );
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (window.Cypress) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.store = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
