import { FormattedMessage } from 'react-intl';
import Button from '../shared/Button/Button';
import { changeCounterState, resetCounter } from '../../api/webSocket';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store/storeConfig';
import { isHiddenName } from '../../helper/counterHelper';
import { ChangeCounterStateMessage, CounterState } from '../../models/counter';

const ButtonContent = styled.div`
    display: flex;
`;

const ResetCounterButton = ({ counterId }: { counterId: string }) => {
    const isConnected = useSelector((state: ApplicationState) => state.auth.isConnected);
    const counterState = useSelector(
        (state: ApplicationState) => state.counters.counterStates.find((c) => c.id == counterId) as CounterState
    );
    const namespaceId = useSelector((state: ApplicationState) => state.namespaces.selectedNamespaceId);
    const reset = () => {
        if (isHiddenName(counterState.name)) {
            const newCounterState: ChangeCounterStateMessage = {
                id: counterId,
                counterIn: 0,
                counterOut: 0,
                counterDiff: 0,
                counterTimestamp: Date.now(),
                counterVersion: Date.now(),
            };
            changeCounterState(newCounterState, namespaceId);
        } else {
            resetCounter(counterId);
        }
    };
    return (
        <Button data-testid="resetCounterButton" onClick={reset} disabled={!isConnected}>
            <ButtonContent>
                <FormattedMessage id="resetCounter" />
            </ButtonContent>
        </Button>
    );
};

export default ResetCounterButton;
