import { useHistory } from 'react-router-dom';
import { useHover } from '../../../hooks/useHover';
import { Tile, TileButton } from './DoorTile.styles';
import { DoorTileContent } from './DoorTileContent';
import { useDecodedParams } from '../../../hooks/useDecodedParams';
import { getRoute, RouteEnum } from '../../../helper/routeHelper';
import { CounterState, isCounterLoading } from '../../../models/counter';

const DoorTile = ({
    counter,
    maxNumberOfPersons = 0,
    isSelected = false,
    hasAnyConnectedRoomReachedLimit = false,
    selectedRoomFilledFromInValue = false,
}: {
    counter: CounterState;
    maxNumberOfPersons?: number;
    isSelected?: boolean;
    hasAnyConnectedRoomReachedLimit?: boolean;
    selectedRoomFilledFromInValue?: boolean;
}) => {
    const { namespaceName, roomName }: { namespaceName: string; roomName: string } = useDecodedParams();
    const history = useHistory();
    const [hoverRef, isHovered] = useHover();
    const loading = isCounterLoading(counter);

    const navigateToDoor = () => {
        history.push(getRoute(RouteEnum.DoorDetails, { namespaceName, roomName, counterName: counter.name }));
    };
    return isSelected ? (
        <Tile isTileLoading={loading} data-testid="transition-tile">
            <DoorTileContent
                maxNumberOfPersons={maxNumberOfPersons}
                counter={counter}
                isHovered={isHovered}
                isLoading={loading}
                hasAnyConnectedRoomReachedLimit={hasAnyConnectedRoomReachedLimit}
                selectedRoomFilledFromInValue={selectedRoomFilledFromInValue}
            />
        </Tile>
    ) : (
        <TileButton onClick={navigateToDoor} ref={hoverRef} isTileLoading={loading} data-testid="transition-tile-button">
            <DoorTileContent
                maxNumberOfPersons={maxNumberOfPersons}
                counter={counter}
                isHovered={isHovered}
                isLoading={loading}
                hasAnyConnectedRoomReachedLimit={hasAnyConnectedRoomReachedLimit}
                selectedRoomFilledFromInValue={selectedRoomFilledFromInValue}
            />
        </TileButton>
    );
};

export default DoorTile;
