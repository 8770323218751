import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { EmptyTile, OutsideTile, RoomName, Tile, TileButton } from './RoomTile.styles';
import TileContent from './TileContent';
import { useDecodedParams } from '../../../hooks/useDecodedParams';
import { getRoute, RouteEnum } from '../../../helper/routeHelper';
import { EMPTY_ROOM, Room, ROOM_OUTSIDE_ID } from '../../../models/room';

export enum DisplayMode {
    Tile,
    MiniTile,
}

const RoomTile = ({
    room,
    criticalThreshold,
    isSelected = false,
    displayMode = DisplayMode.Tile,
}: {
    room: Room;
    criticalThreshold: number;
    isSelected?: boolean;
    displayMode?: DisplayMode;
}) => {
    const { namespaceName }: { namespaceName: string } = useDecodedParams();
    const history = useHistory();

    const { name, limit, numberOfPersons, id } = room;
    const percentage = limit && (limit !== 0 ? Math.round((numberOfPersons / limit) * 100) : 0);

    if (id === EMPTY_ROOM.id) {
        return <EmptyTile data-testid="empty-tile" displayMode={displayMode} />;
    }

    if (id === ROOM_OUTSIDE_ID) {
        return (
            <OutsideTile data-testid="room-outside" displayMode={displayMode}>
                <RoomName displayMode={displayMode}>
                    <FormattedMessage id="roomTile.outside" />
                </RoomName>
            </OutsideTile>
        );
    }

    if (!isSelected) {
        const navigateToRoom = () => {
            history.push(getRoute(RouteEnum.RoomDetail, { namespaceName, roomName: name }));
        };
        return (
            <TileButton onClick={navigateToRoom} data-testid="room-tile-button" displayMode={displayMode}>
                <TileContent name={name} percentage={percentage} displayMode={displayMode} criticalThreshold={criticalThreshold} />
            </TileButton>
        );
    }

    return (
        <Tile data-testid="room-tile" displayMode={displayMode}>
            <TileContent name={name} percentage={percentage} displayMode={displayMode} criticalThreshold={criticalThreshold} />
        </Tile>
    );
};

export default RoomTile;
