import { Link } from 'react-router-dom';
import { TrafficLightIcon } from './DoorDetails.styles';
import TrafficLightRedIcon from '../../assets/TrafficLightRed.svg';
import TrafficLightGreenIcon from '../../assets/TrafficLightGreen.svg';
import { getRoute, RouteEnum } from '../../helper/routeHelper';
import { EMPTY_ROOM, Room, ROOM_OUTSIDE_ID } from '../../models/room';
import { CounterState } from '../../models/counter';

const TrafficLightLink = ({
    namespaceName,
    counterName,
    rooms,
    counter,
}: {
    rooms: Room[];
    counter: CounterState;
    counterName: string;
    namespaceName: string;
}) => {
    const firstRoomWithLimit = rooms.find((r) => r.limit) || EMPTY_ROOM;
    const roomIsClosed = firstRoomWithLimit.numberOfPersons >= (firstRoomWithLimit.limit || Number.POSITIVE_INFINITY) || !counter.doorOpen;

    return firstRoomWithLimit.id !== EMPTY_ROOM.id && firstRoomWithLimit.id !== ROOM_OUTSIDE_ID ? (
        <Link to={getRoute(RouteEnum.TrafficLight, { namespaceName, roomName: firstRoomWithLimit.name, counterName })} target="_blank">
            {roomIsClosed ? (
                <TrafficLightIcon src={TrafficLightRedIcon} alt="Closed" />
            ) : (
                <TrafficLightIcon src={TrafficLightGreenIcon} alt="Open" />
            )}
        </Link>
    ) : (
        <div />
    );
};

export default TrafficLightLink;
