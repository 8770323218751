import styled from 'styled-components/macro';

export const PageContentContainer = styled.div`
    display: grid;
    grid-template-rows: 3.5rem auto;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletLandscapeWidth}) {
        grid-template-columns: 3.5rem calc(100vw - 3.5rem);
        grid-template-rows: initial;
    }
    min-height: 100vh;
`;
export const PageContent = styled.div`
    padding: 2rem;
    grid-row: 2;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletLandscapeWidth}) {
        grid-column: 2;
        grid-row: initial;
    }
`;
