import { ErrorAlt } from '@styled-icons/boxicons-regular';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { getRoute, RouteEnum } from '../../helper/routeHelper';

const Page = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ErrorText = styled.div`
    text-align: center;
`;

const InvalidRoute = () => (
    <Page>
        <div>
            <ErrorAlt height="100%" width="100%" />
            <ErrorText>Diese Seite existiert nicht.</ErrorText>
            <ErrorText>
                Zurück zur <Link to={getRoute(RouteEnum.NamespaceSelection)}>Startseite</Link>
            </ErrorText>
        </div>
    </Page>
);

export default InvalidRoute;
