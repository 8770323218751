import styled from 'styled-components/macro';
import { PeopleArrows } from '@styled-icons/fa-solid';
import { SurgicalMask } from '@styled-icons/remix-fill';

export const KeepDistanceIcon = styled(PeopleArrows)`
    height: 100%;
    color: ${(props) => props.theme.trafficLight.textColor};
    justify-self: center;
`;
export const KeepDistance = styled.div`
    display: grid;
    grid-template-rows: 68% 32%;
    height: 20vh;
    align-items: center;
    justify-content: center;
    padding: 1rem;
`;
export const KeepDistanceMessage = styled.div`
    font-size: 3vh;
    color: ${(props) => props.theme.trafficLight.textColor};
    align-self: end;
    text-align: center;
`;
export const FaceMaskIcon = styled(SurgicalMask)`
    height: 100%;
    color: ${(props) => props.theme.trafficLight.textColor};
    justify-self: center;
`;
export const FaceMask = styled.div`
    display: grid;
    grid-template-rows: 68% 32%;
    height: 20vh;
    align-items: center;
    justify-content: center;
    padding: 1rem;
`;
export const FaceMaskMessage = styled.div`
    font-size: 3vh;
    color: ${(props) => props.theme.trafficLight.textColor};
    align-self: end;
    text-align: center;
`;
export const Page = styled.div`
    display: grid;
    height: 100vh;
    grid-template-rows: 80% 20%;
`;
export const TrafficLightArea = styled.div<{ open: boolean; indeterminate: boolean }>`
    background-color: ${(props) =>
        props.indeterminate ? props.theme.trafficLight.gray : props.open ? props.theme.trafficLight.green : props.theme.trafficLight.red};
    transition: ${(props) => `background-color ${props.theme.animations.transitionDuration} ${props.theme.animations.easingFunction}`};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.trafficLight.textColor};
`;
export const Footer = styled.div`
    background: ${(props) => props.theme.footer};
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
`;
export const Header = styled.div`
    font-size: 12vw;
`;
export const Message = styled.div`
    font-size: 5vw;
    margin-top: 2rem;
`;
