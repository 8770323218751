import { UrlParams } from '../../models';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store/storeConfig';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { validateUrlParams } from './UrlParamValidatorHelper';
import { getRoute, RouteEnum } from '../../helper/routeHelper';
import { Room } from '../../models/room';
import { Namespace } from '../../models/namespace';
import { CounterState } from '../../models/counter';

type UrlParamValidatorProps = UrlParams & {
    children: JSX.Element;
};
const UrlParamValidator: FC<UrlParamValidatorProps> = ({ namespaceName, roomName, counterName, children }: UrlParamValidatorProps) => {
    const {
        namespaces,
        isConnected,
        rooms,
        isLoadingRooms,
        counters,
        isLoadingCounters,
    }: {
        namespaces: Namespace[];
        isConnected: boolean;
        rooms: Room[];
        isLoadingRooms: boolean;
        counters: CounterState[];
        isLoadingCounters: boolean;
    } = useSelector((state: ApplicationState) => ({
        isConnected: state.auth.isConnected,
        namespaces: state.namespaces.namespaces,
        isLoadingRooms: state.rooms.isLoading,
        rooms: state.rooms.rooms,
        counters: state.counters.counterStates,
        isLoadingCounters: state.counters.isLoading,
    }));
    const [isNamespaceParamValid, setIsNamespaceParamValid] = useState(true);
    const [isRoomParamValid, setIsRoomParamValid] = useState(true);
    const [isCounterParamValid, setIsCounterParamValid] = useState(true);
    const [isCounterAssignedToRoom, setIsCounterAssignedToRoom] = useState(true);

    useEffect(() => {
        const result = validateUrlParams({
            namespaceName,
            counterName,
            roomName,
            isConnected,
            isLoadingRooms,
            isLoadingCounters,
            namespaces,
            rooms,
            counters,
        });
        const { isCounterAssignedToRoom, isCounterValid, isNamespaceValid, isRoomValid } = result;
        setIsNamespaceParamValid(isNamespaceValid);
        setIsRoomParamValid(isRoomValid);
        setIsCounterParamValid(isCounterValid);
        setIsCounterAssignedToRoom(isCounterAssignedToRoom);
    }, [namespaceName, counterName, roomName, isConnected, isLoadingRooms, isLoadingCounters, namespaces, rooms, counters]);

    return !isNamespaceParamValid ? (
        <div>
            <p>
                <FormattedMessage id="namespaceParamInvalid" />
            </p>
            <Link to={getRoute(RouteEnum.NamespaceSelection)}>
                <FormattedMessage id="namespaceSelectionLink" />
            </Link>
        </div>
    ) : !isRoomParamValid ? (
        <div>
            <p>
                <FormattedMessage id="roomParamInvalid" />
            </p>
            <Link to={getRoute(RouteEnum.RoomOverview, { namespaceName: namespaceName || '' })}>
                <FormattedMessage id="roomSelectionLink" />
            </Link>
        </div>
    ) : !isCounterParamValid ? (
        <div>
            <p>
                <FormattedMessage id="counterParamInvalid" />
            </p>
            <Link
                to={getRoute(RouteEnum.RoomDetail, {
                    namespaceName: namespaceName || '',
                    roomName: roomName || '',
                })}
            >
                <FormattedMessage id="roomDetailLink" />
            </Link>
        </div>
    ) : !isCounterAssignedToRoom ? (
        <div>
            <p>
                <FormattedMessage id="counterNotAssignedToRoom" />
            </p>
            <Link
                to={getRoute(RouteEnum.RoomDetail, {
                    namespaceName: namespaceName || '',
                    roomName: roomName || '',
                })}
            >
                <FormattedMessage id="roomDetailLink" />
            </Link>
        </div>
    ) : (
        <>{children}</>
    );
};

export default UrlParamValidator;
