import { ActionType, createAction } from 'typesafe-actions';
import { Namespace } from '../../models/namespace';

export const setInitialNamespaces = createAction('INITIAL_NAMESPACES')<Namespace[]>();
export const setSelectedNamespaceId = createAction('SET_SELECTED_NAMESPACE')<string>();
export const resetSelectedNamespaceId = createAction('RESET_SELECTED_NAMESPACE')();
export const setCriticalThreshold = createAction('SET_CRITICAL_THRESHOLD')<{ namespaceId: string; criticalThreshold: number }>();
export const submitCriticalThreshold = createAction('SUBMIT_CRITICAL_THRESHOLD')<{ namespaceId: string; criticalThreshold: number }>();
export const submitCriticalThresholdSucceeded = createAction('SUBMIT_CRITICAL_THRESHOLD_SUCCEEDED')<{ namespaceId: string }>();
export const submitCriticalThresholdFailed = createAction('SUBMIT_CRITICAL_THRESHOLD_FAILED')<{ namespaceId: string }>();

export type NamespacesActions =
    | ActionType<typeof setInitialNamespaces>
    | ActionType<typeof setSelectedNamespaceId>
    | ActionType<typeof resetSelectedNamespaceId>
    | ActionType<typeof setCriticalThreshold>
    | ActionType<typeof submitCriticalThreshold>
    | ActionType<typeof submitCriticalThresholdSucceeded>
    | ActionType<typeof submitCriticalThresholdFailed>;
