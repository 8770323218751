import styled from 'styled-components/macro';
import { lightGray, veryLightGray } from '../styles/theme';
import { VictoryLabelProps } from 'victory';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { RoomReportWithLimit } from '../../models/room';

const TOOLTIP_FONT_SIZE = 24;

const Entry = styled.span`
    &:first-child {
        font-weight: bold;
    }
    &:nth-child(even) {
        text-align: right;
    }
`;

const Grid = styled.div`
    display: inline-grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: repeat(auto);
    background-color: ${veryLightGray};
    border-radius: 2px;
    border: 2px solid ${lightGray};
    padding: 1rem;
    grid-column-gap: 2rem;
    line-height: 1.25;
    font-size: 24px;
    font-family: 'Mulish', 'Montserrat', sans-serif;
`;

const PersonsPerHourChartSummaryTooltip = ({
    text,
    ...props
}: VictoryLabelProps & { width?: number; height?: number; flyoutWidth?: number; flyoutHeight?: number; datum?: RoomReportWithLimit }) => {
    const intl = useIntl();
    const textWithHour = [
        props.datum?.hour + ':00 ' + intl.formatMessage({ id: 'personPerHoursChart.hourTooltip' }) + '\t',
        ...(text as string[]),
    ];
    return (
        <g>
            <foreignObject
                x={Math.max((props.x || 0) - 200, 0)}
                y={(props.y || 0) - (textWithHour.length + 2) * TOOLTIP_FONT_SIZE * 1.25}
                width="100%"
                height="100%"
                style={{
                    overflow: 'visible',
                }}
            >
                <Grid>
                    {textWithHour.map((entry, i) => {
                        const subEntries = entry.split('\t');
                        return (
                            <Fragment key={i}>
                                {subEntries.map((subEntry, j) => {
                                    return <Entry key={j}>{subEntry}</Entry>;
                                })}
                            </Fragment>
                        );
                    })}
                </Grid>
            </foreignObject>
        </g>
    );
};

export default PersonsPerHourChartSummaryTooltip;
