export interface RoomConfig {
    id: string;
    name: string;
    limit?: number;
}

export interface CounterConfig {
    id: string;
    name: string;
    roomIdsFilledFromInValue: string[];
    roomIdsFilledFromOutValue: string[];
}

export interface ConfigFile {
    rooms: RoomConfig[];
    counters: CounterConfig[];
}

export const EMPTY_CONFIG_FILE: ConfigFile = {
    counters: [],
    rooms: [],
};
