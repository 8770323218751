import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store/storeConfig';
import { nonMutatingSort, orderByName } from '../../helper/sortingHelper';
import RoomConfigs from './RoomConfigs';
import CounterConfigs from './CounterConfigs';
import ConfigFileSelectionArea from './ConfigFileSelectionArea';
import { getAllRooms, getCounterConfigsWithRoomNames } from '../../store/selector/overlappingSelectors';
import UrlParamValidator from '../UrlParamValidator/UrlParamValidator';
import { useDecodedParams } from '../../hooks/useDecodedParams';
import SegmentedControl from '../shared/SegmentedControl/SegmentedControl';
import { getSelectedNamespace } from '../../store/selector/namespaceSelectors';
import { defaultCriticalThreshold } from '../../helper/roomHelper';
import { submitCriticalThreshold } from '../../store/actions/namespacesActions';
import { submitCriticalThresholdToServer } from '../../api/webSocket';
import { ConfigFile, RoomConfig } from '../../models/config';

const ConfigOverviewContainer = styled.div`
    display: grid;
    grid-gap: 2rem;

    grid-template-rows: max-content max-content;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletLandscapeWidth}) {
        grid-template-columns: max-content max-content;
        grid-template-rows: initial;
    }
`;

const Configuration = () => {
    const [configFile, setConfigFile] = useState<ConfigFile>({ counters: [], rooms: [] });
    const [editMode, setEditMode] = useState<'Limit' | 'File' | 'None'>('None');
    const [isSaving, setIsSaving] = useState(false);

    const intl = useIntl();
    const dispatch = useDispatch();

    const { namespaceName }: { namespaceName: string } = useDecodedParams();

    const {
        counterConfigsWithRoomNames,
        rooms,
        isRoomConfigLoading,
        isCounterConfigLoading,
        criticalThreshold,
        selectedNamespaceId,
        isSubmittingCriticalThreshold,
        counterConfigs,
    } = useSelector((state: ApplicationState) => {
        const rooms = getAllRooms(state);
        const counterConfigsWithRoomNames = getCounterConfigsWithRoomNames(state);
        const selectedNamespace = getSelectedNamespace(state);
        return {
            counterConfigsWithRoomNames,
            rooms,
            isRoomConfigLoading: state.rooms.isLoading,
            isCounterConfigLoading: state.counters.isLoading,
            criticalThreshold: selectedNamespace?.criticalThreshold || defaultCriticalThreshold,
            isSubmittingCriticalThreshold: state.namespaces.isSubmittingCriticalThreshold,
            selectedNamespaceId: state.namespaces.selectedNamespaceId,
            counterConfigs: state.counters.counterStates,
        };
    });

    useEffect(() => {
        setConfigFile({ counters: [], rooms: [] });
        setEditMode('None');
        setIsSaving(false);
    }, [rooms, counterConfigs]);

    const newCounterConfigsWithAssignedRooms = configFile.counters.map(
        ({ name, roomIdsFilledFromInValue, roomIdsFilledFromOutValue, id }) => {
            const roomsFilledFromInValue = roomIdsFilledFromInValue.map(
                (roomId) =>
                    configFile.rooms.find((room) => room.id === roomId)?.name ||
                    intl.formatMessage({ id: 'config.unknownRoomId' }, { roomId })
            );

            const roomsFilledFromOutValue = roomIdsFilledFromOutValue.map(
                (roomId) =>
                    configFile.rooms.find((room) => room.id === roomId)?.name ||
                    intl.formatMessage({ id: 'config.unknownRoomId' }, { roomId })
            );
            return { name, roomsFilledFromInValue, roomsFilledFromOutValue, id };
        }
    );
    return (
        <>
            <h1>
                <FormattedMessage id="config" />
            </h1>

            <UrlParamValidator namespaceName={namespaceName}>
                <>
                    <h2>
                        <FormattedMessage id="config.thresholdDescription" />
                    </h2>
                    <div>
                        <FormattedMessage id="config.currentThreshold" values={{ threshold: criticalThreshold }} />
                        <SegmentedControl
                            disabled={isSubmittingCriticalThreshold}
                            onChange={(newCriticalThreshold) => {
                                dispatch(
                                    submitCriticalThreshold({
                                        namespaceId: selectedNamespaceId,
                                        criticalThreshold: newCriticalThreshold,
                                    })
                                );
                                submitCriticalThresholdToServer(selectedNamespaceId, newCriticalThreshold);
                            }}
                            segments={[
                                { label: '70%', value: 70 },
                                { label: '80%', value: 80 },
                                { label: '90%', value: 90 },
                                { label: '100%', value: 100 },
                            ]}
                            value={criticalThreshold}
                        />
                    </div>

                    <h2>
                        <FormattedMessage id="config.roomAndDoorConfig" />
                    </h2>

                    <ConfigFileSelectionArea
                        configFile={configFile}
                        setConfigFile={setConfigFile}
                        selectedNamespaceId={selectedNamespaceId}
                        allowEditLimits={rooms.length > 0}
                        onEditLimits={() => {
                            setConfigFile({ rooms, counters: counterConfigs });
                        }}
                        onCancel={() => {
                            setConfigFile({ rooms: [], counters: [] });
                        }}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        isSaving={isSaving}
                        setIsSaving={setIsSaving}
                    />

                    <ConfigOverviewContainer>
                        <RoomConfigs
                            roomConfigs={nonMutatingSort(rooms, orderByName)}
                            headerId="config.currentRoomConfigs"
                            noDataMessageId="config.noCurrentRoomConfigs"
                            isLoading={isRoomConfigLoading}
                        />
                        <RoomConfigs
                            roomConfigs={nonMutatingSort(configFile.rooms, orderByName)}
                            headerId="config.previewRoomConfigs"
                            noDataMessageId="config.selectConfigFileFirst"
                            editLimits={editMode === 'Limit'}
                            onLimitChange={(roomName, limit) => {
                                const roomConfigToUpdate = configFile.rooms.find((room) => room.name === roomName);
                                const updatedRoomConfig: RoomConfig[] = roomConfigToUpdate
                                    ? [{ ...roomConfigToUpdate, limit: Number(limit) || 0 }]
                                    : [];
                                const rooms: RoomConfig[] = [
                                    ...configFile.rooms.filter((room) => room.name !== roomName),
                                    ...updatedRoomConfig,
                                ];
                                setConfigFile({ ...configFile, rooms });
                            }}
                            isSaving={isSaving}
                        />
                    </ConfigOverviewContainer>

                    <ConfigOverviewContainer>
                        <CounterConfigs
                            counterConfigsWithRoomNames={nonMutatingSort(counterConfigsWithRoomNames, orderByName)}
                            headerId="config.currentCounterConfigs"
                            noDataMessageId="config.noCurrentCounterConfigs"
                            isLoading={isCounterConfigLoading}
                        />
                        <CounterConfigs
                            counterConfigsWithRoomNames={nonMutatingSort(newCounterConfigsWithAssignedRooms, orderByName)}
                            headerId="config.previewCounterConfigs"
                            noDataMessageId="config.selectConfigFileFirst"
                        />
                    </ConfigOverviewContainer>
                </>
            </UrlParamValidator>
        </>
    );
};

export default Configuration;
