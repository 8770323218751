import styled from 'styled-components/macro';
import { Menu } from '@styled-icons/boxicons-regular';

export const BurgerMenu = styled(Menu)`
    width: 2rem;
    height: 2rem;
`;
export const Logo = styled.img`
    height: 2rem;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletLandscapeWidth}) {
        width: 2rem;
        height: initial;
    }
`;
export const BurgerNavbar = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    z-index: 900;

    &:focus {
        outline: none;
        & svg {
            background-color: ${(props) => props.theme.navigation.focusColor};
        }
    }

    background-color: ${(props) => props.theme.burgerNavigationMobile.backgroundColor};
    color: ${(props) => props.theme.burgerNavigationMobile.color};

    padding: 0.5rem 2rem 0.5rem 0.5rem;
    border: none;

    height: 3.5rem;
    width: 100vw;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletLandscapeWidth}) {
        width: 3.5rem;
        height: 100vh;
        background-color: ${(props) => props.theme.navigation.backgroundColor};
        padding: 0.5rem 0 0 0;
        color: ${(props) => props.theme.navigation.color};
        flex-direction: column-reverse;
        justify-content: flex-end;
    }
`;
