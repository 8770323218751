import { ApplicationState } from '../../store/storeConfig';
import { getRoomByName } from '../../store/selector/roomsSelectors';
import { getCountersWithAssignedRoomsOnOppositeSideOfRoom } from '../../store/selector/overlappingSelectors';
import { getRoomReportWithLabelAndLimitForBusinessHours, isRoomLimitReached } from '../../helper/roomHelper';
import { getCriticalThreshold } from '../../store/selector/namespaceSelectors';
import { Room, RoomReportWithLimit } from '../../models/room';
import { CounterStateWithAssignedRooms } from '../../models/counter';

export type RoomDetailSelectorResult = {
    currentNumberOfPersons: number;
    capacity?: number;
    counters: CounterStateWithAssignedRooms[];
    room: Room;
    isLoading: boolean;
    isLimitReached: boolean;
    isConnected: boolean;
    selectedNamespaceId: string;
    roomReportEntries: RoomReportWithLimit[];
    roomReportLoading: boolean;
    distancerReportEntries: RoomReportWithLimit[];
    distancerReportLoading: boolean;
    criticalThreshold: number;
    distancerCurrentNumberOfDetections?: number;
};

export const roomDetailSelector = (state: ApplicationState, roomName: string) => {
    const isLoading = state.counters.isLoading;
    const room = getRoomByName(state, roomName);
    const counters = getCountersWithAssignedRoomsOnOppositeSideOfRoom(state, roomName);
    const hasAnyConnectedRoomReachedLimit = counters.some((counter) => counter.assignedRooms.some(isRoomLimitReached));
    const hasCurrentRoomReachedLimit = isRoomLimitReached(room);
    const selectedNamespaceId = state.namespaces.selectedNamespaceId;
    const isLimitReached = hasAnyConnectedRoomReachedLimit || hasCurrentRoomReachedLimit;
    const isConnected = state.auth.isConnected;
    const roomReportFromState = state.rooms.roomReports[room.id];
    const roomReportLoading = roomReportFromState === undefined;
    const roomReportEntries = getRoomReportWithLabelAndLimitForBusinessHours(roomReportFromState, room);
    // TODO: Add distancer to config, obtain id
    const distancerReportFromState = state.distancers.distancerReports['aws-console-test'];
    const distancerReportLoading = distancerReportFromState === undefined;
    // TODO: Adapt for distancer or change name
    const distancerReportEntries = getRoomReportWithLabelAndLimitForBusinessHours(distancerReportFromState, room);
    const distancerCurrentNumberOfDetections = state.distancers.distancerStates['aws-console-test']?.length;
    return {
        currentNumberOfPersons: room.numberOfPersons,
        capacity: room.limit,
        counters,
        room,
        isLoading,
        isLimitReached,
        selectedNamespaceId,
        isConnected,
        roomReportEntries,
        roomReportLoading,
        distancerReportEntries,
        distancerReportLoading,
        distancerCurrentNumberOfDetections,
        criticalThreshold: getCriticalThreshold(state),
    };
};
