import { UserCircle } from '@styled-icons/boxicons-solid';
import styled from 'styled-components/macro';
import SmartCountrLogo from '../../assets/SmartCountr-Logo.png';
import { FormattedMessage } from 'react-intl';
import Button from '../shared/Button/Button';
import { useHistory } from 'react-router-dom';
import { getRoute, RouteEnum } from '../../helper/routeHelper';

const Logo = styled.img`
    align-self: center;
    justify-self: center;
    width: 209px;
`;

const UserIcon = styled(UserCircle)`
    width: 4rem;
    height: 4rem;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
`;

const LogoutContainer = styled.div`
    width: 100vw;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
        width: 25rem;
    }
    background-color: ${(props) => props.theme.login.formBackgroundColor};
    padding: 2rem;
    box-shadow: 0 0 14px 13px rgba(0, 0, 0, 0.1);
`;

const Page = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: ${(props) => props.theme.login.pageBackgroundColor};
    grid-template-rows: 1fr auto 1fr;
`;

const SignInAgainButton = styled(Button)`
    margin-top: 2rem;
`;

function Logout() {
    const history = useHistory();

    return (
        <Page>
            <Logo src={SmartCountrLogo} alt="logo" />
            <LogoutContainer>
                <Header>
                    <h2>
                        <FormattedMessage id="logout" />
                    </h2>
                    <UserIcon />
                </Header>
                <FormattedMessage id="successfullyLoggedOut" />
                <SignInAgainButton onClick={() => history.push(getRoute(RouteEnum.Login))} data-testid="signInAgain-button">
                    <FormattedMessage id="signInAgain" />
                </SignInAgainButton>
            </LogoutContainer>
        </Page>
    );
}

export default Logout;
