import { ActionType, createAction } from 'typesafe-actions';
import { Namespace } from '../../models/namespace';

export const setToken = createAction('SET_TOKEN')<string>();
export const setIsConnected = createAction('SET_IS_CONNECTED')<boolean>();
export const clearToken = createAction('CLEAR_TOKEN')();
export const requestInitClient = createAction('REQUEST_INIT_CLIENT')();
export const initClient = createAction('INIT_CLIENT')<{ namespaces: Namespace[] }>();

export type AuthActions =
    | ActionType<typeof setToken>
    | ActionType<typeof clearToken>
    | ActionType<typeof setIsConnected>
    | ActionType<typeof requestInitClient>
    | ActionType<typeof initClient>;
