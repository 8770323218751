import ConfigFileUpload from './ConfigFileUpload';
import Button from '../shared/Button/Button';
import { uploadConfigFile } from '../../api/webSocket';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { useState } from 'react';
import { ConfigFile, EMPTY_CONFIG_FILE } from '../../models/config';

const Container = styled.div`
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: max-content max-content max-content max-content;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletLandscapeWidth}) {
        grid-template-columns: max-content max-content;
        grid-template-rows: initial;
        grid-row-gap: 2rem;
    }
`;

const SelectedFile = styled.div`
    margin-top: 0.5rem;

    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletLandscapeWidth}) {
        grid-column: 1 / span 2;
    }
`;

const InvalidFileMessage = styled.div`
    color: red;
`;

const StickySelectionArea = styled.div`
    background-color: white;
    padding: 1rem 0;
    width: 100%;
    @media (min-width: ${(props) => props.theme.responsiveDesign.tabletLandscapeWidth}) {
        position: sticky;
        top: 3rem;
    }
`;

const ConfigFileSelectionArea = ({
    configFile,
    setConfigFile,
    selectedNamespaceId,
    allowEditLimits,
    onEditLimits,
    onCancel,
    editMode,
    setEditMode,
    isSaving,
    setIsSaving,
}: {
    configFile: ConfigFile;
    setConfigFile: (configFile: ConfigFile) => void;
    selectedNamespaceId: string;
    allowEditLimits: boolean;
    onEditLimits: () => void;
    onCancel: () => void;
    editMode: 'Limit' | 'File' | 'None';
    setEditMode: (editMode: 'Limit' | 'File' | 'None') => void;
    isSaving: boolean;
    setIsSaving: (isSaving: boolean) => void;
}) => {
    const [selectedFileName, setSelectedFileName] = useState('');
    const [isConfigFileValid, setIsConfigFileValid] = useState(true);

    const intl = useIntl();

    return (
        <StickySelectionArea>
            <Container>
                <Button
                    disabled={!allowEditLimits || editMode === 'File'}
                    onClick={() => {
                        onEditLimits();
                        setEditMode('Limit');
                    }}
                >
                    <FormattedMessage id="config.editLimits" />
                </Button>
                <ConfigFileUpload
                    updateConfigFile={(fileName, configFile) => {
                        setIsConfigFileValid(true);
                        setConfigFile(configFile);
                        setSelectedFileName(fileName);
                        setEditMode('File');
                    }}
                    invalidConfigFile={() => {
                        setIsConfigFileValid(false);
                        setConfigFile(EMPTY_CONFIG_FILE);
                        setEditMode('File');
                    }}
                    disabled={editMode === 'Limit'}
                />
                <Button
                    onClick={() => {
                        uploadConfigFile(configFile, selectedNamespaceId);
                        setIsSaving(true);
                    }}
                    disabled={
                        editMode === 'None' ||
                        isSaving ||
                        (allowEditLimits
                            ? false
                            : configFile.counters.length === 0 || configFile.rooms.length === 0 || selectedNamespaceId === '')
                    }
                >
                    <FormattedMessage id="config.save" />
                </Button>

                <Button
                    onClick={() => {
                        onCancel();
                        setSelectedFileName('');
                        setEditMode('None');
                    }}
                    disabled={editMode === 'None' || isSaving}
                >
                    <FormattedMessage id="config.cancel" />
                </Button>
            </Container>
            {(selectedFileName || !isConfigFileValid) && (
                <SelectedFile>
                    {isConfigFileValid ? (
                        <FormattedMessage
                            id="config.selectedFile"
                            values={{ selectedFileName: selectedFileName || intl.formatMessage({ id: 'config.noFileSelected' }) }}
                        />
                    ) : (
                        <InvalidFileMessage>
                            <FormattedMessage id="config.invalidFile" />
                        </InvalidFileMessage>
                    )}
                </SelectedFile>
            )}
        </StickySelectionArea>
    );
};

export default ConfigFileSelectionArea;
