export function throttle(callback: () => void, limit: number): () => void {
    let wait = false;

    return (): void => {
        if (!wait) {
            callback();
            wait = true;
            setTimeout(function () {
                wait = false;
            }, limit);
        }
    };
}
