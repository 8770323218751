import { FormattedMessage } from 'react-intl';
import { HeaderCell, LoadingCell, NumberCell, Row, Table, TextCell } from '../shared/Table/Table.styles';
import DotLoadingSpinner from '../shared/LoadingSpinner/DotLoadingSpinner';
import { LimitInput } from './LimitInput';

const RoomConfigs = ({
    headerId,
    noDataMessageId,
    roomConfigs,
    isLoading = false,
    editLimits = false,
    onLimitChange = () => {},
    isSaving = false,
}: {
    headerId: string;
    noDataMessageId: string;
    roomConfigs: { name: string; limit?: number }[];
    isLoading?: boolean;
    editLimits?: boolean;
    onLimitChange?: (roomId: string, limit: string) => void;
    isSaving?: boolean;
}) => {
    return (
        <div>
            <h3>
                <FormattedMessage id={headerId} />
            </h3>
            {roomConfigs.length === 0 && !isLoading ? (
                <FormattedMessage id={noDataMessageId} />
            ) : (
                <Table>
                    <thead>
                        <tr>
                            <HeaderCell>
                                <FormattedMessage id="config.roomName" />
                            </HeaderCell>
                            <HeaderCell>
                                <FormattedMessage id="config.limit" />
                            </HeaderCell>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <Row>
                                <td colSpan={2}>
                                    <LoadingCell>
                                        <DotLoadingSpinner />
                                    </LoadingCell>
                                </td>
                            </Row>
                        ) : (
                            roomConfigs.map((roomConfig, index) => (
                                <Row key={index}>
                                    <TextCell>{roomConfig.name}</TextCell>
                                    {editLimits ? (
                                        <td>
                                            <LimitInput
                                                dataTestId={`edit-limit-${roomConfig.name}`}
                                                value={roomConfig.limit}
                                                onChange={(event) => {
                                                    onLimitChange(roomConfig.name, event.target.value);
                                                }}
                                                disabled={isSaving}
                                                autoFocus={index === 0}
                                            />
                                        </td>
                                    ) : (
                                        <NumberCell>{roomConfig.limit || '-'}</NumberCell>
                                    )}
                                </Row>
                            ))
                        )}
                    </tbody>
                </Table>
            )}
        </div>
    );
};

export default RoomConfigs;
