export interface Room {
    id: string;
    name: string;
    limit?: number;
    numberOfPersons: number;
}

export interface RoomMessage {
    id: string;
    roomName: string;
    roomLimit?: number;
    numberOfPersons: number;
}

export interface RoomUpdateMessage {
    id: string;
    numberOfPersons: number;
}

export type RoomHourReportMessageEntry = {
    timestamp: string;
    avgValue: string;
    maxValue: string;
};
export type RoomHourReportEntry = {
    hour: number;
    avgValue: number;
    maxValue: number;
};

export type RoomReportWithLimit = RoomHourReportEntry & {
    limit?: number;
};

export const ROOM_OUTSIDE_ID = 'ROOM_OUTSIDE_ID';
export const EMPTY_ROOM: Room = {
    id: 'EMPTY_ROOM_ID',
    name: '',
    limit: 0,
    numberOfPersons: 0,
};
export const OUTSIDE_ROOM: Room = {
    ...EMPTY_ROOM,
    id: ROOM_OUTSIDE_ID,
};
export type NamespaceHourReportMessageEntry = RoomHourReportMessageEntry & {
    roomId: string;
};
