import styled, { css } from 'styled-components/macro';
import { darkBlue, veryLightGray, white } from '../../styles/theme';

export const Table = styled.table`
    margin: 1rem 0;
    font-family: 'Mulish', 'Montserrat', sans-serif;
`;

export const HeaderBase = css`
    min-width: 10rem;
    line-height: 1.5rem;
    background-color: ${darkBlue};
    color: ${white};
    padding: 0.5rem;
`;
export const HeaderCell = styled.th`
    ${HeaderBase}
    text-align: left;
`;

export const Cell = css`
    line-height: 1rem;
    padding: 0.5rem;
`;

export const TextCell = styled.td`
    ${Cell}
    text-align: left;
`;

export const NumberCell = styled.td`
    ${Cell}
    text-align: right;
`;

export const Row = styled.tr`
    &:nth-child(odd) {
        background-color: ${white};
    }
    &:nth-child(even) {
        background-color: ${veryLightGray};
    }
`;

export const LoadingCell = styled.div`
    ${Cell}
    display: flex;
    justify-content: center;
    background-color: ${veryLightGray};
`;
