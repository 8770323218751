import { EMPTY_ROOM, Room, RoomHourReportEntry, RoomReportWithLimit } from '../models/room';

export const isRoomLimitReached = (room: Room): boolean => (room.limit ? room.numberOfPersons >= room.limit : false);

export const defaultCriticalThreshold = 80;

export const isRoomCritical = (room: Room, criticalWorkloadLimit = defaultCriticalThreshold / 100): boolean =>
    room.limit ? room.numberOfPersons / room.limit >= criticalWorkloadLimit : false;

export const isAnyConnectedRoomLimitReached = (leftRooms: Room[], rightRooms: Room[]): boolean => {
    const firstLeftRoomWithLimit = leftRooms.find((room) => room.limit) || EMPTY_ROOM;
    const firstRightRoomWithLimit = rightRooms.find((room) => room.limit) || EMPTY_ROOM;
    return [firstLeftRoomWithLimit, firstRightRoomWithLimit].some(isRoomLimitReached);
};
export const getRemainingCapacity = (room: Room): number => (room.limit ? room.limit - room.numberOfPersons : 0);

export const BUSINESS_START = 7;
export const BUSINESS_END = 22;
export const SKELETON_LOADING_REPORT_VALUE = 1;

export const getEmptyReportUpUntilBusinessEnd = (isLoading: boolean, limit?: number): RoomHourReportEntry[] => {
    const hoursSoFar = [...Array(BUSINESS_END - BUSINESS_START + 1).keys()];
    const value = isLoading ? limit || SKELETON_LOADING_REPORT_VALUE : 0;
    return hoursSoFar.map((hour) => ({
        hour: BUSINESS_START + hour,
        maxValue: value,
        avgValue: value,
    }));
};

export const getRoomReportWithLabelAndLimitForBusinessHours = (
    roomReportFromState: RoomHourReportEntry[] | undefined,
    room: Room
): RoomReportWithLimit[] => {
    const roomReportLoading = roomReportFromState === undefined;
    const limit = room.limit;
    const emptyRoomReport = getEmptyReportUpUntilBusinessEnd(roomReportLoading, limit);
    return emptyRoomReport.map((emptyReport) => {
        const foundReport = roomReportFromState?.find((report) => report.hour === emptyReport.hour);
        return foundReport
            ? {
                  ...foundReport,
                  limit,
              }
            : { ...emptyReport, limit };
    });
};
