import Ajv from 'ajv';
import roomConfigSchema from './roomConfigSchema.json';

export const validateConfigFile = (configFileContent: unknown): boolean => {
    const ajv = new Ajv();
    const validate = ajv.compile(roomConfigSchema);
    const isValidConfigFile = validate(configFileContent);
    if (!isValidConfigFile) {
        console.error(`ConfigFile contains following errors: ${JSON.stringify(validate.errors)}`);
    }
    return isValidConfigFile;
};
