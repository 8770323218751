import smartCountrLogo from '../../../assets/SmartCountr-Logo.png';
import useMedia from '../../../hooks/useMedia';
import { tabletLandscapeWidth } from '../../styles/theme';
import { BurgerMenu, BurgerNavbar, Logo } from './NavigationTrigger.styles';

const BurgerNavigation = ({
    setNavigationOpen,
    isNavigationOpen,
}: {
    setNavigationOpen: (isOpen: boolean) => void;
    isNavigationOpen: boolean;
}) => {
    const isTabletLandscape = useMedia(`(min-width: ${tabletLandscapeWidth})`);

    return (
        <BurgerNavbar onClick={() => setNavigationOpen(!isNavigationOpen)}>
            <BurgerMenu />
            {!isTabletLandscape && <Logo src={smartCountrLogo} />}
        </BurgerNavbar>
    );
};

export default BurgerNavigation;
