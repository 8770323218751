import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store/storeConfig';
import { getCounterStateByName } from '../../store/selector/countersSelectors';
import { getRoomsGroupedBySide, getRoomsGroupedBySideWithoutSelectedRoom } from '../../store/selector/overlappingSelectors';
import DoorTile from '../shared/DoorTile/DoorTile';
import {
    DoorOpenIcon,
    DoorTileWithTrafficLights,
    HandStopIcon,
    IconWithText,
    PositionCloseDoorButton,
    RoomInfo,
    SceletonIcon,
    SkeletonBlock,
    StopIcon,
} from './DoorDetails.styles';
import CloseDoorButton from './CloseDoorButton';
import TrafficLightLink from './TrafficLightLink';
import { isAnyConnectedRoomLimitReached } from '../../helper/roomHelper';
import useMedia from '../../hooks/useMedia';
import { tabletPortraitWidth } from '../styles/theme';
import React from 'react';
import Rooms from './Rooms';
import { useDecodedParams } from '../../hooks/useDecodedParams';
import { getRoomByName } from '../../store/selector/roomsSelectors';
import UrlParamValidator from '../UrlParamValidator/UrlParamValidator';
import { getCriticalThreshold } from '../../store/selector/namespaceSelectors';
import { EMPTY_ROOM, Room } from '../../models/room';
import { CounterState } from '../../models/counter';

const DoorDetails = () => {
    const { counterName, namespaceName, roomName }: { counterName: string; namespaceName: string; roomName: string } = useDecodedParams();
    const isTabletPortrait = useMedia(`(min-width: ${tabletPortraitWidth})`);

    const {
        leftRooms,
        rightRooms,
        counter,
        anyLimitReached,
        selectedRoomFilledFromInValue,
        isLoading,
        criticalThreshold,
    }: {
        leftRooms: Room[];
        rightRooms: Room[];
        counter: CounterState;
        anyLimitReached: boolean;
        selectedRoomFilledFromInValue: boolean;
        isLoading: boolean;
        criticalThreshold: number;
    } = useSelector((state: ApplicationState) => {
        const isLoading = state.counters.isLoading || state.rooms.isLoading;
        const { roomsOnSelectedRoomSide: leftRooms, roomsOnOppositeSide: rightRooms } = isLoading
            ? { roomsOnSelectedRoomSide: [EMPTY_ROOM], roomsOnOppositeSide: [EMPTY_ROOM] }
            : roomName
            ? getRoomsGroupedBySide(state, counterName, roomName)
            : getRoomsGroupedBySideWithoutSelectedRoom(state, counterName);
        const counter = getCounterStateByName(state, counterName);
        const room = getRoomByName(state, roomName);
        const selectedRoomFilledFromInValue = counter.roomIdsFilledFromInValue.some((roomId) => room.id === roomId);
        const anyLimitReached = isAnyConnectedRoomLimitReached(leftRooms, rightRooms);
        return {
            isLoading,
            leftRooms,
            rightRooms,
            counter,
            anyLimitReached,
            selectedRoomFilledFromInValue,
            criticalThreshold: getCriticalThreshold(state),
        };
    });

    return (
        <>
            <h1>
                <FormattedMessage id="door.title" values={{ counterName }} />
            </h1>
            <UrlParamValidator namespaceName={namespaceName} roomName={roomName} counterName={counterName}>
                <>
                    <h2>
                        <FormattedMessage id="door.details" />
                    </h2>
                    {isLoading && (
                        <IconWithText>
                            <SceletonIcon />
                            &nbsp;
                            <SkeletonBlock>&nbsp;</SkeletonBlock>
                        </IconWithText>
                    )}
                    {!isLoading && counter.doorOpen && !anyLimitReached && (
                        <IconWithText>
                            <DoorOpenIcon />
                            &nbsp;
                            <FormattedMessage id="door.open" />
                        </IconWithText>
                    )}
                    {!isLoading && !counter.doorOpen && (
                        <IconWithText>
                            <HandStopIcon />
                            <div>
                                &nbsp;
                                <FormattedMessage id="door.closed" />
                            </div>
                        </IconWithText>
                    )}
                    {!isLoading && anyLimitReached && (
                        <IconWithText>
                            <StopIcon />
                            <div>
                                &nbsp;
                                <FormattedMessage id="door.autoClosed" />
                            </div>
                        </IconWithText>
                    )}
                    <h2>
                        <FormattedMessage id="door.connectedRooms" />
                    </h2>
                    <RoomInfo>
                        <Rooms rooms={leftRooms} isTabletPortrait={isTabletPortrait} criticalThreshold={criticalThreshold} />
                        <DoorTileWithTrafficLights>
                            <TrafficLightLink
                                rooms={rightRooms}
                                counter={counter}
                                namespaceName={namespaceName}
                                counterName={counterName}
                            />
                            <DoorTile
                                counter={counter}
                                isSelected
                                hasAnyConnectedRoomReachedLimit={anyLimitReached}
                                selectedRoomFilledFromInValue={selectedRoomFilledFromInValue}
                            />
                            <TrafficLightLink rooms={leftRooms} counter={counter} namespaceName={namespaceName} counterName={counterName} />
                        </DoorTileWithTrafficLights>
                        <Rooms rooms={rightRooms} isTabletPortrait={isTabletPortrait} criticalThreshold={criticalThreshold} />
                        <PositionCloseDoorButton>
                            <CloseDoorButton counter={counter} />
                        </PositionCloseDoorButton>
                    </RoomInfo>
                </>
            </UrlParamValidator>
        </>
    );
};

export default DoorDetails;
