import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { countersReducer, StateOfCounters } from './reducer/countersReducer';
import { roomsReducer, StateOfRooms } from './reducer/roomsReducer';
import { authReducer, StateOfAuth } from './reducer/authReducer';
import { namespacesReducer, StateOfNamespaces } from './reducer/namespacesReducer';
import { settingsReducer, StateOfSettings } from './reducer/settingsReducer';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/rootSaga';
import { distancerReducer, StateOfDistancers } from './reducer/distancerReducer';

export interface ApplicationState {
    counters: StateOfCounters;
    distancers: StateOfDistancers;
    rooms: StateOfRooms;
    auth: StateOfAuth;
    namespaces: StateOfNamespaces;
    settings: StateOfSettings;
}

const rootReducer = combineReducers({
    counters: countersReducer,
    distancers: distancerReducer,
    rooms: roomsReducer,
    auth: authReducer,
    namespaces: namespacesReducer,
    settings: settingsReducer,
});

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [...getDefaultMiddleware(), sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export default store;
