import { ActionType, createAction } from 'typesafe-actions';
import { DistancerState } from '../reducer/distancerReducer';
import { RoomHourReportEntry } from '../../models/room';

export const updateDistancerState = createAction('UPDATE_DISTANCER_STATE')<{
    distancerState: DistancerState;
    distancerId: string;
}>();

export type DistancerHourReportEntry = RoomHourReportEntry;

export type DistancerReport = DistancerHourReportEntry[];

export const updateDistancerReport = createAction('UPDATE_DISTANCER_REPORT')<{
    distancerId: string;
    distancerReport: DistancerReport;
}>();

export const getDistancerReport = createAction('GET_DISTANCER_REPORT')<{ namespaceId: string; distancerId: string }>();

export type DistancerActions =
    | ActionType<typeof updateDistancerState>
    | ActionType<typeof getDistancerReport>
    | ActionType<typeof updateDistancerReport>;
