import { useParams } from 'react-router-dom';
import { UrlParams } from '../models';

const decode = (encodedParam: string) =>
    encodedParam
        .replace('%3B', ';')
        .replace('%2C', ',')
        .replace('%2F', '/')
        .replace('%3F', '?')
        .replace('%3A', ':')
        .replace('%40', '@')
        .replace('%26', '&')
        .replace('%3D', '+')
        .replace('%3', '=')
        .replace('%24', '$');

export type UseDecodedParamsType = {
    namespaceName: string;
    roomName: string;
    counterName: string;
};

export const useDecodedParams = (): UseDecodedParamsType => {
    const { namespaceName, roomName, counterName }: UrlParams = useParams();

    return {
        namespaceName: decode(namespaceName || ''),
        roomName: decode(roomName || ''),
        counterName: decode(counterName || ''),
    };
};
