import messages_de from './de.json';
import messages_en from './en.json';
import { MessageFormatElement } from 'react-intl';

export const availableLocales = ['de', 'en'];

export function loadLocaleData(locale: string): Record<string, string> | Record<string, MessageFormatElement[]> {
    switch (locale) {
        case 'de':
            return messages_de;
        default:
            return messages_en;
    }
}
