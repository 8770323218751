import styled from 'styled-components/macro';

const Button = styled.button`
    background-color: ${(props) => props.theme.button.backgroundColor};
    color: ${(props) => props.theme.button.textColor};
    padding: 0.5rem;
    height: 2rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
        outline: none;
        background-color: ${(props) => props.theme.button.backgroundColorHover};
    }

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        background-color: ${(props) => props.theme.button.disabledBackgroundColor};
        color: ${(props) => props.theme.button.disabledTextColor};
    }

    &:hover:not(:disabled) {
        outline: none;
        background-color: ${(props) => props.theme.button.backgroundColorHover};
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    transition: background-color ${(props) => props.theme.animations.transitionDuration} ${(props) => props.theme.animations.easingFunction};
`;

export default Button;
