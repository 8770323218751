import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store/storeConfig';
import { DistancerState } from '../../store/reducer/distancerReducer';
import Canvas from '../shared/Canvas/Canvas';
import { DistancerMessage } from '../../models/distancer';

const DistancerCanvas = () => {
    const distancerState: DistancerState = useSelector((state: ApplicationState) => state.distancers.distancerStates['aws-console-test']);

    const drawCoordinate = (ctx: CanvasRenderingContext2D, x: number, y: number, crit: boolean) => {
        if (crit) {
            const radgrad = ctx.createRadialGradient(x, y, 0, x, y, 16);
            radgrad.addColorStop(0, 'rgba(255,0,0,1)');
            radgrad.addColorStop(0.8, 'rgba(228,0,0,.1)');
            radgrad.addColorStop(1, 'rgba(228,0,0,0)');
            ctx.fillStyle = radgrad;
        } else {
            ctx.fillStyle = '#22F4AE';
            const radgrad = ctx.createRadialGradient(x, y, 0, x, y, 16);
            radgrad.addColorStop(0, 'rgba(34, 244, 174, 1)');
            radgrad.addColorStop(0.8, 'rgba(34, 244, 174, .1)');
            radgrad.addColorStop(1, 'rgba(34, 244, 174, 0)');
            ctx.fillStyle = radgrad;
        }
        ctx.fillRect(0, 0, WIDTH, HEIGHT);
    };

    const clearCanvas = (ctx: CanvasRenderingContext2D) => {
        // Store the current transformation matrix
        ctx.save();

        // Use the identity matrix while clearing the canvas
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        // Restore the transform
        ctx.restore();
    };

    const draw = (ctx: CanvasRenderingContext2D) => {
        clearCanvas(ctx);

        for (let i = 0; i < distancerState.length; i++) {
            const dm: DistancerMessage = distancerState[i];
            drawCoordinate(ctx, dm.field_pos.x, dm.field_pos.y, dm.messages.includes(VIOLATING));
        }
        ctx.lineWidth = 4;
        ctx.strokeStyle = 'black';
        ctx.strokeRect(0, 0, WIDTH, HEIGHT);
    };

    return <Canvas onClick={() => {}} draw={draw} height={HEIGHT} width={WIDTH} />;
};

const VIOLATING = 'VIOLATING_MIN_DISTANCE';
const WIDTH = 600;
const HEIGHT = 400;

export default DistancerCanvas;
