import { ApplicationState } from '../storeConfig';
import { defaultCriticalThreshold } from '../../helper/roomHelper';
import { Namespace } from '../../models/namespace';

export const getSelectedNamespace = ({ namespaces }: ApplicationState): Namespace | null => {
    const selectedNamespace = namespaces.namespaces.find((namespace) => namespace.id === namespaces.selectedNamespaceId);
    return selectedNamespace !== undefined ? selectedNamespace : null;
};

export const getNamespaceIdByName = ({ namespaces }: ApplicationState, namespaceName: string): string => {
    const selectedNamespace = namespaces.namespaces.find((namespace) => namespace.name === namespaceName);
    if (selectedNamespace !== undefined) {
        return selectedNamespace.id;
    }
    return '';
};

export const getCriticalThreshold = (state: ApplicationState) => {
    const selectedNamespace = getSelectedNamespace(state);
    return selectedNamespace?.criticalThreshold || defaultCriticalThreshold;
};
export const getSelectedNamespaceId = (state: ApplicationState) => state.namespaces.selectedNamespaceId;
