import React, { useState } from 'react';
import { DesktopRoomsContainer, MobileRoomsContainer } from './DoorDetails.styles';
import { nonMutatingSort, orderByName } from '../../helper/sortingHelper';
import RoomTile from '../shared/RoomTile/RoomTile';
import { Swiper, SwiperSlide } from 'swiper/react';
import MobilePagination from '../shared/MobilePagination/MobilePagination';
import { Room } from '../../models/room';

const Rooms = ({ rooms, isTabletPortrait, criticalThreshold }: { rooms: Room[]; isTabletPortrait: boolean; criticalThreshold: number }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const roomsSortedByName = nonMutatingSort(rooms, orderByName);

    return (
        <>
            {isTabletPortrait ? (
                <DesktopRoomsContainer>
                    {roomsSortedByName.map((room) => (
                        <RoomTile room={room} key={room.id} criticalThreshold={criticalThreshold} />
                    ))}
                </DesktopRoomsContainer>
            ) : (
                <MobileRoomsContainer>
                    <Swiper spaceBetween={16} observer onActiveIndexChange={({ activeIndex }) => setActiveIndex(activeIndex)}>
                        {roomsSortedByName.map((room) => (
                            <SwiperSlide key={room.id}>
                                <RoomTile room={room} criticalThreshold={criticalThreshold} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <MobilePagination items={rooms} activeIndex={activeIndex} />
                </MobileRoomsContainer>
            )}
        </>
    );
};
export default Rooms;
