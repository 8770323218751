import { ApplicationState } from '../storeConfig';
import { CounterState, EMPTY_COUNTER_STATE } from '../../models/counter';

export const isDoorOpenByCounterName = (state: ApplicationState, counterName: string): boolean | undefined => {
    const counter = getCounterStateByName(state, counterName);
    return counter.id !== EMPTY_COUNTER_STATE.id ? counter.doorOpen : undefined;
};

export const getCounterStateByName = ({ counters }: ApplicationState, counterName: string): CounterState => {
    if (!counters.isLoading) {
        const selectedCounter = counters.counterStates.find((counter) => counter.name === counterName);
        if (selectedCounter !== undefined) {
            return selectedCounter;
        }
    }
    return EMPTY_COUNTER_STATE;
};
