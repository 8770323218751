import { AssignedRooms, AssignedRoomTile, Container, DoorTileContainer } from './RoomDetail.styles';
import DoorTile from '../shared/DoorTile/DoorTile';
import RoomTile, { DisplayMode } from '../shared/RoomTile/RoomTile';
import { nonMutatingSort, orderByLimit } from '../../helper/sortingHelper';
import { CounterStateWithAssignedRooms } from '../../models/counter';

type CounterStatesWithAssignedRoomsProps = {
    counterStateWithAssignedRooms: CounterStateWithAssignedRooms;
    maxNumberOfPersons: number;
    isLimitReached: boolean;
    roomName: string;
    isTabletPortrait: boolean;
    selectedRoomFilledFromInValue: boolean;
    criticalThreshold: number;
};

const CounterWithAssignedRooms = ({
    counterStateWithAssignedRooms,
    maxNumberOfPersons,
    isLimitReached,
    roomName,
    isTabletPortrait,
    selectedRoomFilledFromInValue,
    criticalThreshold,
}: CounterStatesWithAssignedRoomsProps) => {
    const { counterState, assignedRooms } = counterStateWithAssignedRooms;

    return (
        <Container>
            <DoorTileContainer>
                <DoorTile
                    selectedRoomFilledFromInValue={selectedRoomFilledFromInValue}
                    counter={counterState}
                    maxNumberOfPersons={maxNumberOfPersons}
                    hasAnyConnectedRoomReachedLimit={isLimitReached}
                />
            </DoorTileContainer>
            <AssignedRooms>
                {nonMutatingSort(assignedRooms, orderByLimit).map((room, roomIndex) => (
                    <AssignedRoomTile
                        key={roomName + counterState.id + '.' + roomIndex}
                        index={roomIndex}
                        numberOfRooms={assignedRooms.length}
                    >
                        <RoomTile
                            room={room}
                            displayMode={isTabletPortrait ? DisplayMode.Tile : DisplayMode.MiniTile}
                            criticalThreshold={criticalThreshold}
                        />
                    </AssignedRoomTile>
                ))}
            </AssignedRooms>
        </Container>
    );
};

export default CounterWithAssignedRooms;
