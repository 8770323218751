import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store/storeConfig';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PersonsPerHourChart from '../RoomDetail/PersonsPerHourChart';
import styled from 'styled-components/macro';
import { nonMutatingSort, orderByRoomName } from '../../helper/sortingHelper';
import { Link } from 'react-router-dom';
import { useDecodedParams } from '../../hooks/useDecodedParams';
import { allRoomsReportSelector, AllRoomsReportSelectorResult, RoomWithReport } from './AllRoomsReport.selector';
import { getReport } from '../../store/actions/roomsActions';
import { getRoute, RouteEnum } from '../../helper/routeHelper';

const AllRooms = styled.div`
    display: grid;
    grid-column-gap: 4rem;
    grid-row-gap: 2rem;
    align-items: stretch;
    grid-template-columns: repeat(auto-fill, ${(props) => props.theme.roomReport.mobileWidth}px);

    @media (min-width: ${(props) => props.theme.responsiveDesign.smartphoneLandscapeWidth}) {
        grid-template-columns: repeat(auto-fill, ${(props) => props.theme.roomReport.width}px);
    }
`;
const RoomReport = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

const RoomHeader = styled.h2`
    width: ${(props) => props.theme.roomReport.width};
    overflow: hidden;
    display: inline-block;
`;

const SkeletonHeader = styled.h2`
    width: 75%;
    overflow: hidden;
    height: 1em;
    display: inline-block;
    border-radius: 0.5em;
    background-color: ${(props) => props.theme.skeleton.backgroundColor};
`;

const SkeletonChart = styled.div`
    background-color: ${(props) => props.theme.skeleton.backgroundColor};
    height: 114px;
    width: ${(props) => props.theme.roomReport.mobileWidth}px;

    @media (min-width: ${(props) => props.theme.responsiveDesign.smartphoneLandscapeWidth}) {
        width: ${(props) => props.theme.roomReport.width}px;
    }
`;

const AllRoomsReport = () => {
    const dispatch = useDispatch();
    const { namespaceName }: { namespaceName: string } = useDecodedParams();
    const {
        roomReports,
        criticalThreshold,
        isLoadingRooms,
        selectedNamespaceId,
        maxValue,
    }: AllRoomsReportSelectorResult = useSelector((state: ApplicationState) => allRoomsReportSelector(state));

    useEffect(() => {
        if (selectedNamespaceId !== '') {
            dispatch(getReport({ namespaceId: selectedNamespaceId, type: 'room-reports' }));
        }
    }, [selectedNamespaceId]);

    return (
        <>
            <h1>
                <FormattedMessage id="allRoomsReport.title" />
            </h1>
            <AllRooms>
                {isLoadingRooms && (
                    <>
                        <RoomReport data-testid="skeleton-report">
                            <SkeletonHeader />
                            <SkeletonChart />
                        </RoomReport>
                        <RoomReport data-testid="skeleton-report">
                            <SkeletonHeader />
                            <SkeletonChart />
                        </RoomReport>
                        <RoomReport data-testid="skeleton-report">
                            <SkeletonHeader />
                            <SkeletonChart />
                        </RoomReport>
                    </>
                )}
                {!isLoadingRooms &&
                    nonMutatingSort(roomReports, orderByRoomName).map(({ room, roomReportEntries, roomReportLoading }: RoomWithReport) => (
                        <RoomReport key={room.id}>
                            <Link to={getRoute(RouteEnum.RoomDetail, { namespaceName: namespaceName, roomName: room.name })}>
                                <RoomHeader>{room.name}</RoomHeader>
                            </Link>
                            <PersonsPerHourChart
                                roomReportEntries={roomReportEntries}
                                roomReportLoading={roomReportLoading}
                                actualNumberOfPersons={room.numberOfPersons}
                                limit={room.limit}
                                maxValue={maxValue}
                                criticalThreshold={criticalThreshold}
                            />
                        </RoomReport>
                    ))}
            </AllRooms>
        </>
    );
};

export default AllRoomsReport;
