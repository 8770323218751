import { EMPTY_ROOM, Room } from '../../models/room';
import { Namespace } from '../../models/namespace';
import { CounterState, EMPTY_COUNTER_STATE } from '../../models/counter';

type ValidationResult = {
    isNamespaceValid: boolean;
    isRoomValid: boolean;
    isCounterValid: boolean;
    isCounterAssignedToRoom: boolean;
};

const isStringEmpty = (value?: string): boolean => value === '' || value === undefined || value === null;

export type UrlValidatorParams = {
    namespaceName?: string;
    roomName?: string;
    counterName?: string;
    isConnected: boolean;
    isLoadingRooms: boolean;
    isLoadingCounters: boolean;
    namespaces: Namespace[];
    rooms: Room[];
    counters: CounterState[];
};

export const EVERYTHING_VALID: ValidationResult = {
    isCounterAssignedToRoom: true,
    isCounterValid: true,
    isNamespaceValid: true,
    isRoomValid: true,
};

export const validateUrlParams = ({
    namespaceName,
    roomName,
    counterName,
    isConnected,
    isLoadingRooms,
    isLoadingCounters,
    namespaces,
    rooms,
    counters,
}: UrlValidatorParams): ValidationResult => {
    let newSelectedRoomId = EMPTY_ROOM.id;
    if (isConnected) {
        if (!namespaces.some((namespace) => namespace.name === namespaceName)) {
            return {
                ...EVERYTHING_VALID,
                isNamespaceValid: false,
            };
        }
    }
    if (isConnected && !isStringEmpty(roomName) && !isLoadingRooms) {
        newSelectedRoomId = rooms.find((room) => room.name === roomName)?.id || EMPTY_ROOM.id;
        if (newSelectedRoomId === EMPTY_ROOM.id) {
            return {
                ...EVERYTHING_VALID,
                isRoomValid: false,
            };
        }
    }
    if (isConnected && !isStringEmpty(counterName) && !isLoadingCounters && newSelectedRoomId !== EMPTY_ROOM.id) {
        const counter = counters.find((counter) => counter.name === counterName) || EMPTY_COUNTER_STATE;
        const isAssigned = [...counter.roomIdsFilledFromInValue, ...counter.roomIdsFilledFromOutValue].some(
            (roomId) => roomId === newSelectedRoomId
        );
        if (counter === EMPTY_COUNTER_STATE) {
            return {
                ...EVERYTHING_VALID,
                isCounterValid: false,
            };
        } else if (!isAssigned) {
            return {
                ...EVERYTHING_VALID,
                isCounterAssignedToRoom: false,
            };
        }
    }
    return { ...EVERYTHING_VALID };
};
