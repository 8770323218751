import { Bar, Barchart, Label, RoomName } from './RoomTile.styles';
import { DisplayMode } from './RoomTile';

const TileContent = ({
    name,
    percentage,
    displayMode,
    criticalThreshold,
}: {
    name: string;
    percentage?: number;
    displayMode: DisplayMode;
    criticalThreshold: number;
}) => (
    <>
        <RoomName displayMode={displayMode}>{name}</RoomName>
        {displayMode === DisplayMode.Tile && (percentage || percentage === 0) && (
            <Barchart data-testid="barchart">
                <Bar $percentage={percentage} $criticalThreshold={criticalThreshold} />
                <Label percentage={percentage}>{percentage}%</Label>
            </Barchart>
        )}
    </>
);

export default TileContent;
