import { createGlobalStyle } from 'styled-components/macro';
import reset from 'styled-reset';
import { blue } from './theme';

export const GlobalStyles = createGlobalStyle`
	${reset}
	html {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        scroll-behavior: smooth;
    }
    
    html,
    body,
    body > #root {
        height: 100vh;
        line-height: 1.2;
    }
    
	*, :after, :before {
			box-sizing: border-box;
	}
    
    h1 {
        font-weight: 300;
        font-size: 1.5rem;

        @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
            font-size: 3rem;
        }
    }
    h2 {
        font-weight: 300;
        margin-top: 2rem;
        margin-bottom: 0.75rem;
        font-size: 1.25rem;

        @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
            font-size: 2rem;
        }
    }
    
     h3 {
        font-weight: 300;
        margin-top: 1.5rem;
        margin-bottom: 0.75rem;
        font-size: 1.1rem;

        @media (min-width: ${(props) => props.theme.responsiveDesign.tabletPortraitWidth}) {
            font-size: 1.3rem;
        }
    }
     
    a {
        text-decoration: none;
        color: ${blue};
        

        &:hover {
          text-decoration: underline;
        }
    }
    
    button {
        cursor: pointer;
        font-size: inherit;
        font-family: inherit;
        border: none;
    }
    
    input {
        font-size: inherit;
        font-family: inherit;
    }

		p {
			margin: 1rem 0 2rem 0;
		}
`;
